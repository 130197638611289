import './SidePanel.css';

import React from 'react';

type ThisComponentProps = {
    className?: string;
    children?: any;
};

const SidePanel = ({ className, children }: ThisComponentProps) => (
    <div className={`side-panel ${className}`}>{children}</div>
);

export default SidePanel;
