import './VehicleHistory.css';

import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { action, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import MainStore from '../../store/mainStore';
import SidePanel from '../SidePanel';
import VehicleInfo from '../VehicleInfo';

interface ThisRouteProps {
    id: string;
}

interface ThisComponentProps extends WithTranslation {
    stores: MainStore;
    vehicleId: number | undefined;
}

@inject('stores')
@observer
class VehicleHistory extends React.Component<ThisComponentProps, {}> {
    props: any;

    // keep track of currently opened log to draw an appropriate item on the map
    @observable private currentLog: Undef<LogItem<IVehicle>>;

    // trigger the function before closing the component to kill the observer
    private killDataChangeObserver: Undef<Function>;

    getStores(): MainStore {
        return this.props.stores as MainStore;
    }

    componentDidMount(): void {
        // observe the changes of routeLogs
        this.killDataChangeObserver = reaction(
            () => this.getStores().vehicleStore.vehicleHistory,
            this.dataChangeObserver.bind(this)
        );

        this.getStores().configStore.verifyDataLoaded();
    }

    componentWillUnmount(): void {
        // kill the observer
        this.killDataChangeObserver && this.killDataChangeObserver();
    }

    // triggered when the data-source has been updated (fetched from the API)
    @action dataChangeObserver() {
        const stores = this.getStores();
        if ((stores.vehicleStore.vehicleHistory || []).length > 0) {
            const vehicleLog = stores.vehicleStore.vehicleHistory[0];
            this.setCurrentLog(vehicleLog);
        }
    }

    // set/unset the selected log item
    @action setCurrentLog(log: Undef<LogItem<IVehicle>>) {
        this.currentLog = this.currentLog === log ? undefined : log;
    }

    render() {
        const { t, stores }: { t: any, stores: MainStore } = this.props;
        const vehicleLogs = (stores as MainStore).vehicleStore.vehicleHistory;

        return (
            <>
                <SidePanel>
                    {vehicleLogs && vehicleLogs.length ? (
                        <Accordion activeKey={`${(this.currentLog || {}).id}`}>
                            {vehicleLogs.map((i, index, logs) => {
                                // latest first -> previous version is next item
                                const prev = index < logs.length - 1 ? logs[index + 1] : undefined;
                                return (
                                    <Card text="white" border="light" key={i.id}>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            eventKey={`${i.id}`}
                                            onClick={this.setCurrentLog.bind(this, i)}
                                        >
                                            <b className="float-right" title={i.event}>
                                                {t(`log.event.${i.event}`)}
                                            </b>
                                            {moment(i.timestamp).format('LT L')}
                                            {' - '}
                                            {t(`log.reason.${i.reason}`)}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={`${i.id}`}>
                                            <Card.Body>
                                                <VehicleInfo
                                                    vehicleLog={i}
                                                    prevLog={prev}
                                                    attributeConfs={stores.configStore.currentAttributes}
                                                    capacityConfs={stores.configStore.currentCapacities} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                );
                            })}
                        </Accordion>
                    ) : (
                        <div>No results</div>
                    )}
                </SidePanel>
            </>
        );
    }
}

export default withTranslation()(VehicleHistory);
