import './EventSelect.css';

import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';


interface EventOption {
    label: string;
    value: string;
}

export interface EventSelectProps {
    id?: string;
    onChange: (selectedEvents: string[]) => void;
    logs: LogItem<any>[];
}

export default function EventSelect(props: EventSelectProps) {
    const { t } = useTranslation();

    const [options, setOptions] = useState<EventOption[]>([]);

    useEffect(() => {
        // Get all unique event names from logs
        const events = props.logs.reduce((uniqueEvents, log) => {
            if (log.event && !uniqueEvents.includes(log.event)) {
                uniqueEvents.push(log.event);
            }
            return uniqueEvents;
        }, [] as string[]);

        // Create options and sort by translated label.
        const eventOptions = _.sortBy(events.map(event => ({
            value: event,
            label: t('log.event.' + event),
        })), 'label');

        setOptions(eventOptions);
    }, [props.logs]);

    return (
        <Select
            id={props.id}
            isMulti
            options={options}
            onChange={(selectedOptions: EventOption[] | null) =>
                props.onChange(selectedOptions ? selectedOptions.map((o) => o.value) : [])
            }
            classNamePrefix="event-select"
            placeholder={t('input.placeholder.eventFilter')}
            noOptionsMessage={() => t('input.placeholder.searchNotFound')}
            // With this setting the options don't get hidden when the container of this
            // component is not as tall as the options.
            menuPosition={'fixed'}
        />
    );
}
