import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Polyline } from 'react-leaflet';
import PingModel from '../../models/PingModel';

type ThisComponentProps = {
    pings: PingModel[];
};

const PingLine = ({ pings }: ThisComponentProps) => {
    const [positions, setPositions]: [GPS[], any] = useState([]);

    useEffect(() => {
        setPositions(pings.map((p) => p.gps));
    }, [pings]);

    return (
        <Polyline
            type={'pingLine'}
            positions={positions}
            color={'red'}
            dashArray={'5,10'}
            weight={2}
        />
    );
};

export default inject('stores')(observer(PingLine));
