import './ReportSharedTrips.css';

import React from 'react';
import { useTranslation } from 'react-i18next';
import SortTable from '../SortTable';


type ThisComponentProps = {
    report: any;
    toCSV: (elementId: string, colSeparator: string) => void;
};

const getColumns = (t) => {
    const tripDailyColumns = [
        {
            Header: t(`report.sharedTripsColumn.date`),
            accessor: 'day',
        },
        {
            Header: t(`report.sharedTripsColumn.routeId`),
            accessor: 'routeId',
        },
        {
            Header: t(`report.sharedTripsColumn.routeDistance`),
            accessor: 'routeDistance',
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original.routeDistance
                            ? original.routeDistance.toFixed(1)
                            : ''}
                    </div>
                );
            },
        },
        {
            Header: t(`report.sharedTripsColumn.tripId`),
            accessor: 'tripId',
        },
        {
            Header: t(`report.sharedTripsColumn.startTime`),
            accessor: 'startTime',
        },
        {
            Header: t(`report.sharedTripsColumn.endTime`),
            accessor: 'endTime',
        },
        {
            Header: t(`report.sharedTripsColumn.startCityCode`),
            accessor: 'startCityCode',
        },
        {
            Header: t(`report.sharedTripsColumn.startAddress`),
            accessor: 'startAddress',
        },
        {
            Header: t(`report.sharedTripsColumn.startCoordinateX`),
            accessor: 'startLng',
        },
        {
            Header: t(`report.sharedTripsColumn.startCoordinateY`),
            accessor: 'startLat',
        },
        {
            Header: t(`report.sharedTripsColumn.endCityCode`),
            accessor: 'endCityCode',
        },
        {
            Header: t(`report.sharedTripsColumn.endAddress`),
            accessor: 'endAddress',
        },
        {
            Header: t(`report.sharedTripsColumn.endCoordinateX`),
            accessor: 'endLng',
        },
        {
            Header: t(`report.sharedTripsColumn.endCoordinateY`),
            accessor: 'endLat',
        },
        {
            Header: t(`report.sharedTripsColumn.tripDirectDistance`),
            accessor: 'tripDirectDistance',
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original.tripDirectDistance
                            ? original.tripDirectDistance.toFixed(1)
                            : ''}
                    </div>
                );
            },
        },
        {
            Header: t(`report.sharedTripsColumn.contract`),
            accessor: 'contract',
        },
    ];
    return tripDailyColumns;
};

const ReportSharedTrips = ({ report, toCSV }: ThisComponentProps) => {
    const { t } = useTranslation();

    return <>
        {
            report &&
            report.data.length <= 0 &&
            t(`report.noResults`)
        }

        {
            report &&
            report.data.length > 0 && (
                <>
                    <button
                        onClick={() => toCSV('report-table', '\t')}
                        className="btn btn-sm btn-secondary"
                    >
                        {t('button.export')}
                    </button>

                    <SortTable
                        id="report-table"
                        data={report.data}
                        columns={getColumns(t)}
                    />
                </>
            )}

    </>;
};

export default ReportSharedTrips;
