import './App.css';
import './custom.scss';

import React from 'react';
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import PrivateRoute from './components/Routing/PrivateRoute';
import Header from './components/Header';
import BackgroundMap from './components/BackgroundMap';
import VehiclePage from './components/VehiclePage';
import ReportPage from './components/ReportPage/ReportPage';
import HistoryPage from './components/VehicleHistoryPage/VehicleHistoryPage';
import config from './config';
import SearchPage from './components/SearchPage';

// TODO: use of `Auth0` causes our app to re-render itself 4 times during the start-up. Find out why...
const App: React.FC = () => {
    const { rootURL } = config.app;
    return (
        <Router>
            <div className={`App ${config.ui.customerStyle}`}>
                <Header />

                <Switch>
                    {false && <Route path={rootURL} exact />}

                    <PrivateRoute path={rootURL + 'vehicle/:id?'} component={VehiclePage} />

                    <PrivateRoute path={rootURL + 'route/:id?'} component={SearchPage} />

                    <PrivateRoute path={rootURL + 'trip/:id?'} component={SearchPage} />

                    <PrivateRoute path={rootURL + 'search'} component={SearchPage} />

                    <PrivateRoute path={rootURL + 'history/:tab?/:id?'} component={HistoryPage} />

                    <PrivateRoute path={rootURL + 'report'} component={ReportPage} />

                    <Redirect from={rootURL} to={rootURL + 'vehicle'} />
                </Switch>

                <BackgroundMap />
            </div>
        </Router>
    );
};

export default App;
