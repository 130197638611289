import React from 'react';
import { observer } from 'mobx-react';

type ThisComponentProps = {
    value: number;
};

const Meters = ({ value }: ThisComponentProps) => <span>{Math.round(value / 100) / 10} km</span>;

export default observer(Meters);
