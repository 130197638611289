import { action, computed, observable } from 'mobx';
import RouteModel from './RouteModel';
import vehicleApi from '../store/vehicleApi';
import moment from 'moment';

export default class OfferModel implements IOffer {
    id: number;
    routeId: number;
    validUntil: Date;
    status: string;
    rejectReason: any;
    vehicleNumber: Null<number>;
    vehicles: number[];
    startTime: Date;
    updateTime: Date;

    @observable routeLog: Undef<LogItem<RouteModel>[]>;
    @observable actualRoute: Undef<RouteModel>;

    constructor(offer: IOffer) {
        this.id = offer.id;
        this.routeId = offer.routeId;
        this.validUntil = offer.validUntil;
        this.status = offer.status;
        this.rejectReason = offer.rejectReason;
        this.vehicleNumber = offer.vehicleNumber;
        this.vehicles = offer.vehicles;
        this.startTime = offer.startTime;
        this.updateTime = offer.updateTime;
    }

    @computed.struct get time(): { start: Date; end: Date } {
        return { start: this.startTime, end: this.updateTime };
    }

    @action
    async fetchRouteLogs() {
        const tmp: Undef<LogItem<IRoute>[]> = await vehicleApi.getRouteLogs(this.routeId);

        if (tmp && tmp.length > 0) {
            // sort the list first
            tmp.sort((a, b) => +moment(a.timestamp) - +moment(b.timestamp));

            // prepare the data
            this.routeLog = tmp.map((i) => ({ ...i, data: new RouteModel(i.data) }));

            // find an actual version of the route for the offer, OR if not found - get the first from the list
            this.actualRoute =
                this.routeLog //
                    .filter((i) => i.timestamp < this.startTime)
                    .map((i) => i.data)
                    .pop() || this.routeLog[0].data;
        }
    }
}
