import './RoutePanel.css';

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Accordion, Card, Tabs, Tab } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { action, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import MainStore from '../../store/mainStore';
import SidePanel from '../SidePanel';
import RouteInner from '../DetailsPanel/RouteInner';
import RouteModel from '../../models/RouteModel';
import LoadSpinner from '../UI/LoadSpinner';
import PingModel from '../../models/PingModel';
import InfoTable from '../InfoTable';
import TimelineLink from '../UI/TimelineLink';
import PerformTable from '../PerformTable';
import EventSelect, { filterByEvents } from '../EventSelect';
import EventTranslator from '../DetailsPanel/EventTranslator';

interface ThisRouteProps {
    id: string;
}

interface ThisComponentProps extends RouteComponentProps<ThisRouteProps>, WithTranslation {
    stores: MainStore;
}

@inject('stores')
@observer
class RoutePanel extends React.Component<ThisComponentProps, {}> {
    props: any;

    // keep track of currently opened log to draw an appropriate item on the map
    @observable private currentLog: Undef<LogItem<RouteModel>>;
    @observable private currentOffersRound: Undef<IOfferRound>;

    @observable private currentTab: string = 'logs';
    @observable selectedEvents: string[] = [];

    // trigger the function before closing the component to kill the observer
    private killDataChangeObserver: Undef<Function>;

    componentDidMount(): void {
        // observe the changes of routeLogs
        this.killDataChangeObserver = reaction(
            () => this.props.stores.routeStore.routeLogs,
            this.dataChangeObserver.bind(this)
        );
        this.dataChangeObserver();
    }

    componentWillUnmount(): void {
        // clean the map
        this.props.stores.mapStore.clearMap();

        // kill the observer
        this.killDataChangeObserver && this.killDataChangeObserver();
    }

    // triggered when the data-source has been updated (fetched from the API)
    @action dataChangeObserver() {
        const { stores } = this.props;

        // pre-select the first element
        if ((stores.routeStore.routeLogs || []).length > 0) {
            const route = stores.routeStore.routeLogs[0];
            this.setCurrentLog(route);

            this.props.stores.mapStore.setPingLine(
                this.props.stores.routeStore.getPingsFromTo(
                    route.data.time.start,
                    route.data.time.end
                )
            );
        }
    }

    // set/unset the selected log item
    @action setCurrentLog(log: Undef<LogItem<RouteModel>>) {
        this.currentLog = this.currentLog === log ? undefined : log;

        this.props.stores.mapStore.setRoute((this.currentLog || {}).data);
    }

    @action setCurrentTab(id: string) {
        this.currentTab = id;

        if (this.currentTab === 'logs') {
            this.setCurrentOffersRound(undefined);
        }

        if (this.currentTab === 'offers') {
            this.setCurrentLog(undefined);
        }

        if (this.currentTab === 'performs') {
            this.setCurrentOffersRound(undefined);
            this.setCurrentLog(undefined);
        }
    }
    @action async setCurrentOffersRound(round: Undef<IOfferRound>) {
        if (round && round.id !== (this.currentOffersRound || {}).id) {
            this.currentOffersRound = round;

            const routeLogs: Undef<LogItem<RouteModel>[]> = this.props.stores.routeStore.routeLogs;

            const closestLog = (routeLogs || [])
                .filter((i) => moment(i.timestamp) <= moment(round.startTime))
                .pop();

            this.props.stores.mapStore.setRoute((closestLog || {}).data);

            if (!round.pings) {
                round.pings = await this.props.stores.routeStore.fetchClosestPings(
                    round.vehicles,
                    round.startTime
                );
            }

            this.props.stores.mapStore.setPings(round.pings as PingModel[]);
        } else {
            this.currentOffersRound = undefined;
            this.props.stores.mapStore.setPings();
            this.props.stores.mapStore.setRoute();
        }
    }

    highlightPing(round: IOfferRound, vehicleNumber: number, hovered: boolean) {
        const ping = (round.pings || []).find((i) => i.vehicleNumber === vehicleNumber);

        if (ping) {
            (ping as PingModel).hovered = hovered;
        }
    }

    render() {
        const { t, stores } = this.props;

        const routeLogs = stores.routeStore.routeLogs;
        const offersRounds: Undef<IOfferRound[]> =
            this.currentTab === 'offers' ? stores.routeStore.offersRounds : undefined;
        const routePerforms: Undef<IPerform[]> =
            this.currentTab === 'performs' ? stores.routeStore.routePerforms : undefined;

        return (
            <>
                {routeLogs && routeLogs.length > 0 && (
                    <SidePanel>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={this.currentTab}
                            onSelect={this.setCurrentTab.bind(this)}
                        >
                            <Tab
                                className={'tabs-title'}
                                eventKey="title"
                                title={<h2>{t('title.route', { id: routeLogs[0].data.id })}</h2>}
                                disabled
                            />

                            <Tab eventKey="logs" title={t(`label.logs`)}>
                                <EventSelect
                                    id="route-panel-event-select"
                                    logs={routeLogs}
                                    onChange={(selectedEvents) =>
                                        (this.selectedEvents = selectedEvents)
                                    }
                                />
                                <Accordion activeKey={`${(this.currentLog || {}).id}`}>
                                    {routeLogs
                                        .filter((l) => filterByEvents(l, this.selectedEvents))
                                        .map((i) => (
                                            <Card text="white" border="light" key={i.id}>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey={`${i.id}`}
                                                    onClick={this.setCurrentLog.bind(this, i)}
                                                >
                                                    <b className="float-right" title={i.event}>
                                                        <EventTranslator logItem={i} />
                                                    </b>
                                                    {moment(i.timestamp).format('LT L')}
                                                    {' - '}
                                                    {t(`log.reason.${i.reason}`)}
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={`${i.id}`}>
                                                    <Card.Body>
                                                        <RouteInner
                                                            item={i.data}
                                                            log={i}
                                                            showVehicleLink={true}
                                                        />
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                </Accordion>
                            </Tab>
                            <Tab eventKey="offers" title={t(`label.offers`)}>
                                {!offersRounds && <LoadSpinner />}
                                {offersRounds && (
                                    <Accordion
                                        activeKey={`offers_round_${
                                            (this.currentOffersRound || {}).id
                                        }`}
                                    >
                                        {offersRounds.map((r) => (
                                            <Card
                                                text="white"
                                                border="light"
                                                key={`offers_round_${r.id}`}
                                            >
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey={`offers_round_${r.id}`}
                                                    onClick={this.setCurrentOffersRound.bind(
                                                        this,
                                                        r
                                                    )}
                                                >
                                                    <b className="float-right">
                                                        {t(`label.offersRoundN`, {
                                                            value: r.id + 1,
                                                        })}
                                                    </b>
                                                    {`${moment(r.startTime).format('LT L')} - ${t(
                                                        `offer.status.${r.status}`
                                                    )} (${r.offers.length}/${r.vehicles.length})`}
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                    eventKey={`offers_round_${r.id}`}
                                                >
                                                    <Card.Body>
                                                        <InfoTable
                                                            rows={{
                                                                offeredTo: r.vehicles.join(', '),
                                                            }}
                                                        />

                                                        <h5 className="mt-3">
                                                            {t(`label.offers`)}
                                                        </h5>

                                                        <table className="list-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        {t(`label.vehicleNumber`)}
                                                                    </th>
                                                                    <th>{t(`label.startTime`)}</th>
                                                                    <th>{t(`label.status`)}</th>
                                                                    <th>
                                                                        {t(`label.rejectReason`)}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {r.offers.map((o) => (
                                                                    <tr
                                                                        key={o.id}
                                                                        onMouseEnter={this.highlightPing.bind(
                                                                            this,
                                                                            r,
                                                                            o.vehicleNumber!,
                                                                            true
                                                                        )}
                                                                        onMouseLeave={this.highlightPing.bind(
                                                                            this,
                                                                            r,
                                                                            o.vehicleNumber!,
                                                                            false
                                                                        )}
                                                                    >
                                                                        <td>
                                                                            <TimelineLink
                                                                                vehicle={
                                                                                    o.vehicleNumber!
                                                                                }
                                                                                target={o}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {moment(
                                                                                o.startTime
                                                                            ).format('LTS')}
                                                                        </td>
                                                                        <td>
                                                                            {t(
                                                                                `offer.status.${o.status}`
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {o.rejectReason &&
                                                                                t(
                                                                                    `offer.reason.${o.rejectReason}`
                                                                                )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                )}
                            </Tab>
                            <Tab eventKey="performs" title={t('label.performs')}>
                                <PerformTable performs={routePerforms} stores={this.props.stores} />
                            </Tab>
                        </Tabs>
                    </SidePanel>
                )}
            </>
        );
    }
}

export default withTranslation()(RoutePanel);
