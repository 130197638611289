import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import jwtDecode from 'jwt-decode';
import { getEnv } from './config';
import config from './config';

// @todo: add new interface properties if needed
interface Auth0ProviderProps {
    children: any;
    onRedirectCallback?: Function;
    domain: string;
    client_id: string;
    redirect_uri: string;
    audience: string;
}

interface IAuth0Context {
    isAuthenticated?: boolean;
    user?: any;
    loading?: boolean;
    popupOpen?: boolean;
    loginWithPopup?: (params: any) => void;
    handleRedirectCallback?: () => void;
    getIdTokenClaims?: (...p: any) => any;
    loginWithRedirect?: (...p: any) => any;
    getTokenSilently?: (...p: any) => any;
    getTokenWithPopup?: (...p: any) => any;
    logout?: (...p: any) => any;
}

// A function that routes the user to the right place after login
const DEFAULT_REDIRECT_CALLBACK = (appState: any = undefined) => {
    console.log('APP STATE', appState);
    return window.history.replaceState(
        {},
        document.title,
        appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
    );
};

// TODO: I'm adding this `exploit`, as I don't see any other ways to pass the
// auth0 client to the app (to APIs clients in particular.)
let auth0ClientExt: any;
export async function getAuth0Client() {
    return auth0ClientExt;
}

export const Auth0Context = React.createContext<IAuth0Context>({});

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({
    children,
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    ...initOptions
}: Auth0ProviderProps) => {
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [user, setUser] = useState();
    const [auth0Client, setAuth0] = useState();
    const [loading, setLoading] = useState(true);
    const [popupOpen, setPopupOpen] = useState(false);

    useEffect(() => {
        const initAuth0 = async () => {
            auth0ClientExt = await createAuth0Client(initOptions);
            setAuth0(auth0ClientExt);

            const isAuthenticated = await auth0ClientExt.isAuthenticated();

            setIsAuthenticated(isAuthenticated);

            if (isAuthenticated) {
                const user = await auth0ClientExt.getUser();
                setUser(user);
            }

            setLoading(false);
        };

        initAuth0();
        // eslint-disable-next-line
    }, []);

    const loginWithPopup = async (params = {}) => {
        setPopupOpen(true);
        try {
            await auth0Client!.loginWithPopup(params).catch((i) => console.log(i));

            const user = await auth0Client!.getUser();

            if (user) {
                // check if the token contains the required site permission.
                const token = await auth0Client.getTokenSilently();

                // check if an appropriate `site:...` permission exists
                if (!(jwtDecode(token).permissions as string[]).includes(`site:${getEnv[0]}`)) {
                    console.log('user has no permission');
                    return await logout();
                }

                setUser(user);
                setIsAuthenticated(true);

                setPopupOpen(false);
            } else {
                console.log('user not available...');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const logout = async () => {
        try {
            await auth0Client!.logout({
                returnTo: window.location.origin + config.app.rootURL,
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleRedirectCallback = async () => {
        setLoading(true);
        await auth0Client!.handleRedirectCallback();
        const user = await auth0Client!.getUser();
        setLoading(false);
        setIsAuthenticated(true);
        setUser(user);
    };

    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                loading,
                popupOpen,
                loginWithPopup,
                logout,
                handleRedirectCallback,
                getIdTokenClaims: (...p) => auth0Client!.getIdTokenClaims(...p),
                loginWithRedirect: (...p) => auth0Client!.loginWithRedirect(...p),
                getTokenSilently: (...p) => auth0Client!.getTokenSilently(...p),
                getTokenWithPopup: (...p) => auth0Client!.getTokenWithPopup(...p),
                //logout: (...p) => auth0Client!.logout(...p)
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};
