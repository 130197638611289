import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import SidePanel from '../SidePanel';
import RouteModel from '../../models/RouteModel';
import MainStore from '../../store/mainStore';
import { WithTranslation, withTranslation } from 'react-i18next';
import RouteInner from './RouteInner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../config';

interface ThisRouteProps {
    itemId: string;
}

interface ThisComponentProps extends RouteComponentProps<ThisRouteProps>, WithTranslation {
    stores: MainStore;
}

@inject('stores')
@observer
class RouteDetails extends React.Component<ThisComponentProps, {}> {
    props: any;

    render() {
        const { t } = this.props;
        const { rootURL } = config.app;

        const item: Undef<RouteModel> = this.props.stores.vehicleStore.getRoute(
            +this.props.match.params.itemId
        );

        return item ? (
            <SidePanel>
                <h2>
                    <Link className="inline-link" to={`${rootURL}route/${item.id}`}>
                        {t('title.route', { id: item.id })}{' '}
                        <i>
                            <FontAwesomeIcon icon="external-link-alt" />
                        </i>
                    </Link>
                </h2>

                <RouteInner item={item} />
            </SidePanel>
        ) : null;
    }
}

export default withTranslation()(RouteDetails);
