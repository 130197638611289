import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from '../../react-auth0-spa';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
    const { isAuthenticated } = useAuth0();

    /* TODO:
        In theory this commented code should have opened the auth popup automatically if there is no session (when navigation by direct link).
        However it does not work as it should be, because modern browsers do not allow to open a popup before any interactions with the page were made by the user (mouse click for example).
        We might improve it somehow in future, so leave it here commented
    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }
        console.log(loading, isAuthenticated, path);

        (async () => {
            console.log('aaa1');
            await loginWithPopup!({appState: { targetUrl: path }});
            console.log('aaa2');
        })();
    }, [loading, isAuthenticated, loginWithPopup, path]);
    */

    return isAuthenticated ? <Route path={path} component={Component} {...rest} /> : null;
};

export default PrivateRoute;
