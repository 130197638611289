import { action, observable } from 'mobx';
import RouteModel from '../models/RouteModel';
import PingModel from '../models/PingModel';

export default class MapStore {
    @observable pings: PingModel[] = [];
    @observable routes: RouteModel[] = [];
    @observable pingLines: PingModel[][] = [];
    @observable performs: ICompletion[] = [];

    @action setPing(ping: Undef<PingModel>) {
        this.clearPings();

        if (ping) {
            this.pings.push(ping);
        }
    }

    @action setPings(pings: Undef<PingModel[]> = undefined) {
        this.clearPings();

        if (pings) {
            this.pings.push(...pings);
        }
    }

    @action setRoute(route: Undef<RouteModel>) {
        this.clearRoutes();

        if (route) {
            this.routes.push(route);
        }
    }

    @action setPingLine(pings: Undef<PingModel[]>) {
        this.clearPingLines();

        if (pings) {
            this.pingLines.push(pings);
        }
    }

    @action setPerform(perform: Undef<ICompletion> = undefined) {
        this.clearPerforms();

        if (perform) {
            this.performs.push(perform);
        }
    }

    @action clearMap() {
        this.clearPings();
        this.clearRoutes();
        this.clearPingLines();
        this.clearPerforms();
    }

    @action clearPings() {
        while (this.pings.length > 0) {
            this.pings.shift();
        }
    }

    @action clearRoutes() {
        while (this.routes.length > 0) {
            this.routes.shift();
        }
    }

    @action clearPingLines() {
        while (this.pingLines.length > 0) {
            this.pingLines.shift();
        }
    }

    @action clearPerforms() {
        while (this.performs.length > 0) {
            this.performs.shift();
        }
    }
}
