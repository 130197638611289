import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { inject } from 'mobx-react';
import moment from 'moment';
import InfoTable from '../InfoTable';
import SidePanel from '../SidePanel';
import MainStore from '../../store/mainStore';

interface ThisRouteProps {
    itemId: string;
}

interface ThisComponentProps extends RouteComponentProps<ThisRouteProps> {
    stores: MainStore;
}

const BreakDetails = ({ match, stores }: ThisComponentProps) => {
    const item: Undef<IBreak> = stores.vehicleStore.getBreak(+match.params.itemId);

    return item ? (
        <SidePanel>
            <h2>Break {item.id}</h2>

            <InfoTable
                rows={{
                    vehicle: item.vehicleNumber,
                    shift: item.shiftId,
                    duration: item.duration,
                    period: `${moment(item.periodStart).format('L LT')} - ${moment(
                        item.periodEnd
                    ).format('L LT')}`,
                    planned: `${moment(item.plannedStart).format('L LT')} - ${moment(
                        item.plannedEnd
                    ).format('L LT')}`,
                    estimated: `${
                        item.estimatedStart ? moment(item.estimatedStart).format('L LT') : '?'
                    } - ${item.estimatedEnd ? moment(item.estimatedEnd).format('L LT') : '?'}`,
                    completion:
                        item.completion && item.completion.timestamp
                            ? `${item.completion.status} - ${moment(
                                  item.completion.timestamp
                              ).format('L LT')}`
                            : '',
                }}
            />
        </SidePanel>
    ) : null;
};

export default inject('stores')(BreakDetails);
