import './SearchInput.css';

import React from 'react';
import AsyncSelect from 'react-select/async';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import debounce from 'debounce-promise';
import { inject, observer } from 'mobx-react';

const Q_DEBOUNCE = 300;

type ComponentParams = {
    value: Null<RouteOption | TripOption>;
    onChange: Function;
    stores?: any;
};

const isOptionSelected = (o: IRoute, selectedOptions: IRoute[]): boolean => {
    return selectedOptions.length > 0 && selectedOptions[0].id === o.id;
};

const prepareQ = (q: string): string => {
    return q.trim();
};

const getHighlightString = (t: any, option: RouteOption | TripOption, q): string => {
    let s = '';
    if (option.optionType === 'route') {
        const r = option as RouteOption;
        s = `${t('label.route')} ${r.id}`;

        if (r.externalId && r.externalId.includes(q)) {
            s += ` (${r.externalId})`;
        }

        r.trips.forEach((trip) => {
            if (trip.externalId && trip.externalId.includes(q)) {
                s += `, ${t('label.trip')} ${trip.id} (${trip.externalId})`;
            } else if (
                `${trip.id}`.includes(q) ||
                (trip.externalId && trip.externalId.includes(q))
            ) {
                s += `, ${t('label.trip')} ${trip.id}`;
            }
        });
    } else if (option.optionType === 'trip') {
        const tripMap = option as TripOption;
        s = `${t('label.trip')} ${tripMap.id}`;
        const externalIds = [
            ...tripMap.tripWithRoutes.map((twr) => twr.trip.externalId).filter((eid) => eid),
            ...(tripMap.tripNoRoutes ? [tripMap.tripNoRoutes.externalId] : []),
        ];
        const externalIdMatch = externalIds.find((eid) => eid.includes(q));
        if (externalIds.length && !!externalIdMatch) {
            s += ` (${externalIdMatch})`;
        }

        if (tripMap.tripWithRoutes.length === 1) {
            s += `, ${t('label.route')}`;
        } else if (tripMap.tripWithRoutes.length > 1) {
            s += `, ${t('label.routes')}`;
        }
        tripMap.tripWithRoutes.forEach((twr) => {
            if (twr.route?.id) {
                s += ` ${twr.route.id}`;
            }
        });
    }
    return s;
};

const SearchInput = ({ value, onChange, stores }: ComponentParams) => {
    const { t } = useTranslation();

    const loadOptions = async (q: string): Promise<(RouteOption | TripOption)[]> => {
        q = prepareQ(q);

        if (q.length < 2) {
            return [];
        }

        const routes = await stores.routeStore.searchRoutes(q);
        const trips = await stores.tripStore.searchTrips(q);
        const tripsRouteHistoryMap = routes.reduce((acc: TripRouteHistoryMap, curr: IRoute) => {
            curr.trips.forEach((t) => {
                acc[t.id] = acc[t.id]
                    ? [...acc[t.id], { trip: t, route: curr }]
                    : [{ trip: t, route: curr }];
            });
            return acc;
        }, []);
        const tripWithRouteOptions = Object.keys(tripsRouteHistoryMap).map(
            (key): TripOption => ({
                id: tripsRouteHistoryMap[key][0].trip.id,
                tripWithRoutes: tripsRouteHistoryMap[key],
                tripNoRoutes: null,
                optionType: 'trip',
            })
        );
        const tripWithRouteIds = tripWithRouteOptions.map((t) => t.id);
        const tripNoRouteOptions = trips
            .filter((t) => !tripWithRouteIds.includes(t.id))
            .map(
                (t): TripOption => ({
                    id: t.id,
                    tripWithRoutes: [],
                    tripNoRoutes: t,
                    optionType: 'trip',
                })
            );
        const routeOptions = routes.map(
            (r): RouteOption => ({
                ...r,
                optionType: 'route',
            })
        );
        return [...tripNoRouteOptions, ...routeOptions, ...tripWithRouteOptions];
    };

    const debouncedLoadOptions = debounce(loadOptions, Q_DEBOUNCE);

    const formatOptionLabel = (
        option: RouteOption | TripOption,
        { inputValue }: { inputValue: string }
    ) => (
        <Highlighter
            searchWords={[prepareQ(inputValue)]}
            textToHighlight={getHighlightString(t, option, inputValue)}
        />
    );

    const setNoOptionsMessage = ({ inputValue }) => {
        return inputValue === ''
            ? t('input.placeholder.fuzzySearching')
            : t('input.placeholder.searchNotFound', { q: inputValue });
    };

    return (
        <AsyncSelect
            cacheOptions={false}
            blurInputOnSelect={true}
            placeholder={t('input.placeholder.fuzzySearch')}
            classNamePrefix="react-select"
            value={value}
            loadOptions={debouncedLoadOptions}
            formatOptionLabel={formatOptionLabel}
            //getOptionLabel={option => `${option.name}, ${option.phone}, ${option.email}`} //option.name
            noOptionsMessage={setNoOptionsMessage}
            isOptionSelected={isOptionSelected}
            onChange={onChange}
        />
    );
};

export default inject('stores')(observer(SearchInput));
