import { action, computed, observable, reaction } from 'mobx';
import vehicleApi, { VehicleApi } from './vehicleApi';
import moment from 'moment';
import { promisedComputed } from 'computed-async-mobx';
import RouteModel from '../models/RouteModel';
import PingModel from '../models/PingModel';
import PingCluster from '../utils/PingCluster';
import GanttEvent from '../models/GanttEvent';
import OfferModel from '../models/OfferModel';

export default class VehicleStore {
    private vehicleApi: VehicleApi;

    @observable searchVehicle: Undef<number>;
    @observable searchDate: Undef<Date>;
    @observable searchPeriod: Undef<number>;
    @observable vehicleHistoryNumber: Undef<number>;

    @observable pings: PingModel[] = [];
    @observable routes: RouteModel[] = [];
    @observable shifts: IShift[] = [];
    @observable breaks: IBreak[] = [];
    @observable offers: OfferModel[] = [];
    @observable ganttEvents: GanttEvent[] = [];
    @observable pingsRanges: [number, number][] = [];

    private pingCluster: PingCluster;

    // a destructor function (actually, not really needed...)
    private killDataChangeObserver: Function;

    constructor() {
        this.vehicleApi = vehicleApi;

        this.pingCluster = new PingCluster();

        // perform an appropriate reaction when the main vehicle schedule data structure is changed.
        this.killDataChangeObserver = reaction(
            () => this.vehicleScheduleData,
            this.dataChangeObserver.bind(this)
        );
    }

    @action
    dataChangeObserver() {
        this.routes = this.vehicleScheduleData
            ? this.vehicleScheduleData.routes.map((i) => new RouteModel(i))
            : [];
        this.shifts = this.vehicleScheduleData ? this.vehicleScheduleData.shifts : [];
        this.breaks = this.vehicleScheduleData ? this.vehicleScheduleData.breaks : [];
        this.offers = this.vehicleScheduleData
            ? this.vehicleScheduleData.offers.map((i) => new OfferModel(i))
            : [];

        this.pings = this.genPings();
        this.pingsRanges = this.pingCluster.getRanges();

        this.ganttEvents = this.genGanttEvents();
    }

    @action
    genPings() {
        const pings: PingModel[] = this.vehicleScheduleData
            ? this.vehicleScheduleData.pings.map(
                  (i, ind) => new PingModel({ ...i, id: i.id || ind })
              )
            : [];

        pings.sort((a, b) => a.ts - b.ts);

        this.pingCluster.prepare(pings);

        return pings;
    }

    @action
    genGanttEvents() {
        // TODO: this could be improved somehow, by creating an appropriate class, for example, that will handle all this stuff...
        const events: GanttEvent[] = [];

        this.routes.forEach((i) => events.push(new GanttEvent('route', i)));
        this.shifts.forEach((i) => events.push(new GanttEvent('shift', i)));
        this.breaks.forEach((i) => events.push(new GanttEvent('break', i)));
        this.offers.forEach((i) => events.push(new GanttEvent('offer', i)));

        events.sort((a, b) => a.startTime - b.startTime);

        return events;
    }

    @action
    setVehicleHistorySearch(searchVehicle: number) {
        this.vehicleHistoryNumber = searchVehicle;
    }

    @action
    setSearchValues(searchVehicle: number, searchDate: Date, searchPeriod: number) {
        this.setSearchVehicle(searchVehicle);
        this.setSearchDate(searchDate);
        this.setSearchPeriod(searchPeriod);
    }

    @action
    setSearchVehicle(searchVehicle: number) {
        this.searchVehicle = searchVehicle;
    }

    @action
    setSearchDate(searchDate: Date) {
        this.searchDate = searchDate;
    }

    @action
    setSearchPeriod(searchPeriod: number) {
        this.searchPeriod = searchPeriod;
    }

    // TODO: we might change the way the data is fetched. Right now we use a 3rd party library,
    //  but may be it's better to perform a regular async fetch from the server and perform some sort of reaction...
    @computed get vehicleScheduleData(): Undef<VehicleScheduleData> {
        return this.computedVehicleScheduleData.get(); // tmp[this.searchVehicle!];
    }

    computedVehicleScheduleData = promisedComputed(undefined, async () => {
        if (this.searchVehicle && this.searchDate && this.searchPeriod) {
            return await this.vehicleApi.getSchedule(
                this.searchVehicle,
                this.searchDate,
                moment(this.searchDate).add(this.searchPeriod, 'hours').toDate()
            );
        } else {
            return undefined;
        }
    });

    @computed get apiVehicles(): Promise<IVehicle[]> {
        return this.vehicleApi.getVehicles();
    }

    @computed get vehicles(): IVehicle[] {
        return this.computedVehicles.get();
    }

    computedVehicles = promisedComputed([], async () => {
        const vehicles: IVehicle[] = await this.vehicleApi.getVehicles();
        vehicles.sort((a, b) => a.number - b.number);
        return vehicles;
    });

    @computed get vehicleHistory(): LogItem<IVehicle>[] {
        return this.computedVehicleHistory.get();
    }

    computedVehicleHistory = promisedComputed([], async () => {
        if (this.vehicleHistoryNumber) {
            const vehicles: LogItem<IVehicle>[] = await this.vehicleApi.getVehicleHistory(
                this.vehicleHistoryNumber,
                moment('2015-01-01').toDate(), // fixed date to fetch all
                moment().toDate()
            );

            vehicles.sort(
                (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
            );

            return vehicles;
        }
        return [];
    });

    @computed get contracts(): (string | null)[] {
        return this.computedContracts.get();
    }

    computedContracts = promisedComputed([], async () => {
        const contracts: (string | null)[] = await this.vehicleApi.getContracts();

        contracts.sort((a, b) => {
            if (a === null) {
                return -1;
            } else if (b === null) {
                return 1;
            }
            return a > b ? 1 : a < b ? -1 : 0;
        });

        return contracts;
    });

    getRoute(id: number): Undef<RouteModel> {
        return this.routes.find((i) => i.id === id);
    }

    getShift(id: number): Undef<IShift> {
        return this.shifts.find((i) => i.id === id);
    }

    getBreak(id: number): Undef<IBreak> {
        return this.breaks.find((i) => i.id === id);
    }

    getOffer(id: number): Undef<OfferModel> {
        return this.offers.find((i) => i.id === id);
    }

    getClosestPing(ts: number): Undef<PingModel> {
        return this.pingCluster.getClosest(ts);
    }

    getPingsFromTo(from: Date, to: Date): PingModel[] {
        return this.pingCluster.getPath(+moment(from), +moment(to));
    }
}
