import React from 'react';
import { observer } from 'mobx-react';
import { CircleMarker } from 'react-leaflet';
import StopModel from '../../models/StopModel';

type ThisComponentProps = {
    stop: StopModel;
};

function getMarkerColor(type: StopType): string {
    switch (type) {
        case 'pick':
            return 'green';
        case 'drop':
            return 'red';
        case 'flex':
            return 'blue';
        default:
            return 'green';
    }
}

const StopMarker = ({ stop }: ThisComponentProps) => (
    <CircleMarker
        type={'stop'}
        key={`stop-${stop.id}`}
        radius={stop.hovered ? 10 : 5}
        center={stop.location}
        color={getMarkerColor(stop.type)}
        fillColor={stop.selected ? 'yellow' : 'transparent'}
        fillOpacity={0.5}
    />
);

export default observer(StopMarker);
