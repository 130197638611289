import './ReportVehicleActivity.css';

import React from 'react';
import { useTranslation } from 'react-i18next';
import SortTable from '../SortTable';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Seconds from '../Humanize/Seconds';
import Meters from '../Humanize/Meters';
import Ratio from '../Humanize/Ratio';
import moment from 'moment';

type ThisComponentProps = {
    report: any;
    exportname: string;
    lang: string;
};

const performsRatioSort = (a, b, columnID: string): 0 | 1 | -1 => {
    const [x, y] = [
        a.original[columnID] / (a.original.performsTotal || 1),
        b.original[columnID] / (b.original.performsTotal || 1),
    ];

    return x === y ? 0 : x > y ? 1 : -1;
};

const getColumns = (t, reportMeta, lang) => {
    const latePerformValue =
            reportMeta && reportMeta.latePerformValue
                ? reportMeta.latePerformValue
                : '?';
    const columns = [
        {
            Header: t(`report.column.vehicle.self`),
            columns: [
                {
                    Header: t(`report.column.vehicle.number`),
                    accessor: 'vehicleNumber',
                },
                {
                    Header: t(`report.column.vehicle.name`),
                    accessor: 'vehicleName',
                },
            ],
        },
        {
            Header: t(`report.column.routes.self`),
            columns: [
                {
                    Header: t(`report.column.routes.driven`),
                    accessor: 'routesDriven',
                },
                {
                    Header: t(`report.column.routes.distance`),
                    accessor: 'routesDistance',
                    Cell: ({ cell: { value } }) => <Meters value={value} />,
                },
            ],
        },
        {
            Header: t(`report.column.duration.self`),
            columns: [
                {
                    Header: t(`report.column.duration.planned`),
                    accessor: 'routesDurationPlanned',
                    Cell: ({ cell: { value } }) => <Seconds value={value} />,
                },
                {
                    Header: t(`report.column.duration.actual`),
                    accessor: 'routesDurationActual',
                    Cell: ({ cell: { value } }) => <Seconds value={value} />,
                },
            ],
        },
        {
            Header: t(`report.column.performs.self`),
            columns: [
                {
                    Header: t(`report.column.performs.good`),
                    accessor: 'performsGood',
                    Cell: ({ row: { original } }) => (
                        <Ratio value={original.performsGood} total={original.performsTotal} />
                    ),
                    sortType: performsRatioSort,
                },
                {
                    Header: t(`report.column.performs.fine`, { value: latePerformValue }),
                    accessor: 'performsFine',
                    Cell: ({ row: { original } }) => (
                        <Ratio value={original.performsFine} total={original.performsTotal} />
                    ),
                    sortType: performsRatioSort,
                },
                {
                    Header: t(`report.column.performs.late`, { value: latePerformValue }),
                    accessor: 'performsLate',
                    Cell: ({ row: { original } }) => (
                        <Ratio value={original.performsLate} total={original.performsTotal} />
                    ),
                    sortType: performsRatioSort,
                },
            ],
        },
        {
            Header: t(`report.column.offers.self`),
            columns: [
                {
                    Header: t(`report.column.offers.accepted`),
                    accessor: 'offersAccepted',
                },
                {
                    Header: t(`report.column.offers.rejected`),
                    accessor: 'offersRejected',
                },
                {
                    Header: t(`report.column.offers.timeout`),
                    accessor: 'offersTimeout',
                },
                {
                    Header: t('report.column.offers.acceptrate'),
                    accessor: 'offersAcceptRate',
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                {original.offersAcceptRate
                                    ? original.offersAcceptRate.toFixed(1)
                                    : ''}
                            </div>
                        );
                    },
                },
                {
                    Header: t('report.column.offers.avoidrate'),
                    accessor: 'offersAvoidRate',
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                {original.offersAvoidRate
                                    ? original.offersAvoidRate.toFixed(1)
                                    : ''}
                            </div>
                        );
                    },
                },
            ],
        },
        {
            Header: t(`report.column.activity.titleShift`),
            columns: [
                {
                    Header: t(`report.column.activity.onShift`),
                    accessor: 'secondsWithShift',
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                <Seconds value={original.secondsWithShift} />
                                (<Seconds value={original.secondsWithShiftExtension} />)
                            </div>
                        );
                    },
                },
                {
                    Header: t(`report.column.activity.onBreak`),
                    accessor: 'secondsWithShiftBreak',
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                <Seconds value={original.secondsWithShiftBreak} />
                            </div>
                        );
                    },
                },
                {
                    Header: t(`report.column.activity.shiftTime`),
                    accessor: 'totalShiftTime',
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                <Seconds value={original.totalShiftTime} />
                            </div>
                        );
                    },
                },
                {
                    Header: t(`report.column.activity.shiftStart`),
                    accessor: 'firstShiftStart',
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                {original.firstShiftStart ? moment(original.firstShiftStart, undefined, lang).format('L LT') : ''}
                            </div>
                        );
                    },
                },
                {
                    Header: t(`report.column.activity.shiftEnd`),
                    accessor: 'lastShiftEnd',
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                {original.lastShiftEnd ? moment(original.lastShiftEnd, undefined, lang).format('L LT') : ''}
                            </div>
                        );
                    },
                },
            ],
        },
        {
            Header: t(`report.column.activity.titleShiftless`),
            columns: [
                {
                    Header: t(`report.column.activity.onShiftless`),
                    accessor: 'secondsWithoutShift',
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                <Seconds value={original.secondsWithoutShift} />
                            </div>
                        );
                    },
                },
                {
                    Header: t(`report.column.activity.onBreak`),
                    accessor: 'secondsWithBreak',
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                <Seconds value={original.secondsWithBreak} />
                            </div>
                        );
                    },
                },

            ],
        },
    ];
    return columns;
};

const ReportVehicleActivity = ({ report, exportname, lang }: ThisComponentProps) => {
    const { t } = useTranslation();

    return <>
        {
            report &&
            report.data.length <= 0 &&
            t(`report.noResults`)
        }

        {
            report &&
            report.data.length > 0 && (
                <>
                    <ReactHTMLTableToExcel
                        table="report-table"
                        className="btn btn-sm btn-secondary"
                        filename={exportname}
                        sheet="Report"
                        buttonText={t('button.export')}
                    />

                    <SortTable
                        id="report-table"
                        data={report.data}
                        columns={getColumns(t, report.meta, lang)}
                    />
                </>
            )}

    </>;
};

export default ReportVehicleActivity;
