import './GanttChart.css';

import React, { createRef, CSSProperties } from 'react';
import moment from 'moment';
import { action, computed, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import MainStore from '../../store/mainStore';
import { WithTranslation, withTranslation } from 'react-i18next';
import GanttEvent from '../../models/GanttEvent';

interface ThisComponentProps extends WithTranslation {
    events: GanttEvent[];
    pingsRanges: [number, number][];
    stores?: MainStore;
    setPointer: Function;
    pointerTimestamp: Undef<number>;
    min: number;
    max: number;
}

@inject('stores')
@observer
class GanttChart extends React.Component<ThisComponentProps, {}> {
    props: any;

    chartRef: any;

    @observable timelineHovered: boolean = false;
    @observable timelineDragged: boolean = false;
    @observable pointerDragged: boolean = false;
    @observable mouseTip: { x: number; time: string; events: GanttEvent[] } = {
        x: 0,
        time: '',
        events: [] as GanttEvent[],
    };

    @observable private _pointerTimestamp: number = 0;
    @observable private _userMinValue: number = 0;
    @observable private _userMaxValue: number = 0;
    @observable private _userRatio: number = 0;

    globalMouseUpHandlerRef: any;
    globalMouseMoveHandlerRef: any;

    clickedEvent: Undef<GanttEvent>;

    private killEventsReaction: any;

    constructor(props) {
        super(props);

        this.chartRef = createRef();

        this.globalMouseUpHandlerRef = this.globalMouseUpHandler.bind(this);
        this.globalMouseMoveHandlerRef = this.globalMouseMoveHandler.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mouseup', this.globalMouseUpHandlerRef);
        document.addEventListener('mousemove', this.globalMouseMoveHandlerRef);

        this.killEventsReaction = reaction(
            () => this.events,
            () => {
                this._userMinValue = 0;
                this._userMaxValue = 0;
                this._userRatio = 0;
            }
        );
    }

    componentWillUnmount() {
        this.killEventsReaction();

        document.removeEventListener('mouseup', this.globalMouseUpHandlerRef);
        document.removeEventListener('mousemove', this.globalMouseMoveHandlerRef);
    }

    @action
    setViewBounds(min: number, max: number) {
        this._userMinValue = min;
        this._userMaxValue = max;
        this._userRatio = min && max ? 100 / (max - min) : 0;
    }

    @action
    setTimelinePointer(ts: number, event: Undef<GanttEvent>, isOngoing: boolean = false) {
        this._pointerTimestamp = ts;

        this.props.setPointer(
            ts,
            event ? { type: event.type, id: event.data.id } : undefined,
            isOngoing
        );
    }

    globalMouseUpHandler() {
        this.timelineDragged = false;
        this.pointerDragged = false;
    }

    globalMouseMoveHandler(e) {
        const target = this.chartRef.current;

        if (target !== null) {
            const targetBounds = target.getBoundingClientRect() as DOMRect;

            if (this.pointerDragged) {
                const ts =
                    this.userMinValue +
                    Math.round(
                        ((this.userMaxValue - this.userMinValue) * (e.pageX - targetBounds.x)) /
                            targetBounds.width
                    );

                // check if `ts` corresponds any of the events
                this.setTimelinePointer(ts, this.getClosestEvent(ts), true);
            }

            if (this.timelineDragged) {
                const offset =
                    (e.movementX * (this.userMaxValue - this.userMinValue)) / targetBounds.width;

                const [newMinValue, newMaxValue] = [
                    Math.max(this.minValue, this.userMinValue - offset),
                    Math.min(this.maxValue, this.userMaxValue - offset),
                ];

                if (newMinValue !== this.userMinValue && newMaxValue !== this.userMaxValue) {
                    this.setViewBounds(
                        Math.max(this.minValue, this.userMinValue - offset),
                        Math.min(this.maxValue, this.userMaxValue - offset)
                    );
                }
            }

            if (this.timelineHovered) {
                const ts =
                    this.userMinValue +
                    Math.round(
                        ((this.userMaxValue - this.userMinValue) * (e.pageX - targetBounds.x)) /
                            targetBounds.width
                    );

                // get events that match current timestamp
                const hoveredEvents: GanttEvent[] = this.events.filter(
                    (i) => i.startTime < ts && i.endTime > ts
                );

                this.mouseTip = {
                    x: e.pageX - targetBounds.x,
                    time: moment(ts).format('LT'),
                    events: hoveredEvents,
                };
            }
        }
    }

    timelineWheelHandler(e) {
        // lets calculate ratio for left and right offsets
        const offset = Math.round(1 / this.ratio) * ((10 * e.deltaY) / 100);
        const targetBounds = e.currentTarget.getBoundingClientRect();
        const offsetRatio = (e.pageX - targetBounds.x) / targetBounds.width;

        let [newMinValue, newMaxValue] = [
            Math.max(this.minValue, this.userMinValue - offset * offsetRatio),
            Math.min(this.maxValue, this.userMaxValue + offset * (1 - offsetRatio)),
        ];

        if (newMinValue === this.minValue) {
            newMaxValue = Math.min(this.maxValue, this.userMaxValue + offset);
        } else if (newMaxValue === this.maxValue) {
            newMinValue = Math.max(this.minValue, this.userMinValue - offset);
        }

        this.setViewBounds(newMinValue, newMaxValue);
    }

    timelineMouseClickHandler(e) {
        const targetBounds = e.currentTarget.getBoundingClientRect();
        const ts =
            this.userMinValue +
            Math.round(
                ((this.userMaxValue - this.userMinValue) * (e.pageX - targetBounds.x)) /
                    targetBounds.width
            );

        let event: Undef<GanttEvent> = this.clickedEvent || this.getClosestEvent(ts);
        this.setTimelinePointer(ts, event);
        this.clickedEvent = undefined;
    }

    timelineMouseDownHandler() {
        this.mouseTip.x = 0;
        this.timelineDragged = true;
    }

    timelineMouseInHandler() {
        this.timelineHovered = true;
    }

    timelineMouseOutHandler() {
        this.timelineHovered = false;
        this.mouseTip.x = 0;
    }

    timelineKeyDownHandler(e) {
        if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
            let ts = this.pointerTimestamp;

            const anchors: number[] = this.events.reduce(
                (a: number[], i: GanttEvent) => [...a, i.startTime, i.endTime],
                []
            );

            if (e.key === 'ArrowLeft') {
                // move to the previous anchor
                ts = Math.max(
                    ...anchors.filter((i) => i < ts),
                    e.ctrlKey || e.metaKey ? this.minValue : ts - Math.round(1 / this.ratio)
                );
            } else if (e.key === 'ArrowRight') {
                // move to the next anchor
                ts = Math.min(
                    ...anchors.filter((i) => i > ts),
                    e.ctrlKey || e.metaKey ? this.maxValue : ts + Math.round(1 / this.ratio)
                );
            }

            // make sure we fit the timeline
            ts = Math.max(this.minValue, Math.min(this.maxValue, ts));

            const offset =
                ts < this.userMinValue
                    ? ts - this.userMinValue
                    : ts > this.userMaxValue
                    ? ts - this.userMaxValue
                    : 0;
            if (offset !== 0) {
                this.setViewBounds(this.userMinValue + offset, this.userMaxValue + offset);
            }

            // check if `ts` corresponds any of the events
            this.setTimelinePointer(ts, this.getClosestEvent(ts));
        } else if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
            let ts = this.pointerTimestamp;

            const anchors: number[] = this.events.map((i: GanttEvent) => i.startTime);

            if (e.key === 'ArrowUp') {
                // move to the previous anchor
                ts = Math.max(...anchors.filter((i) => i < ts), anchors.shift()!);
            } else if (e.key === 'ArrowDown') {
                // move to the next anchor
                ts = Math.min(...anchors.filter((i) => i > ts), anchors.pop()!);
            }

            const offset =
                ts < this.userMinValue
                    ? ts - this.userMinValue
                    : ts > this.userMaxValue
                    ? ts - this.userMaxValue
                    : 0;
            if (offset !== 0) {
                this.setViewBounds(this.userMinValue + offset, this.userMaxValue + offset);
            }

            // check if `ts` corresponds any of the events
            this.setTimelinePointer(ts, this.getClosestEvent(ts));
        }
    }

    pointerMouseDownHandler(e) {
        e.stopPropagation();
        this.pointerDragged = true;
    }

    eventClickHandler(i: GanttEvent) {
        this.clickedEvent = i;
    }

    // TODO: recomputed TOO often, find out why...
    // the re-computation occurs then the pointer timestamp is updated... maybe if we pass it via vehicle store, it won't happen?
    @computed get events(): GanttEvent[] {
        return this.props ? this.props.events : [];
    }

    @computed get pingsRanges(): [number, number][] {
        return this.props ? this.props.pingsRanges : [];
    }

    @computed get pointerTimestamp(): number {
        return this.props.pointerTimestamp === undefined
            ? 0
            : this._pointerTimestamp || this.props.pointerTimestamp;
    }

    @computed get minValue(): number {
        return Math.min(...this.events.map((i) => i.startTime), this.props.min);
    }

    @computed get maxValue(): number {
        return Math.max(...this.events.map((i) => i.endTime), this.props.max);
    }

    @computed get userMinValue(): number {
        return this._userMinValue || this.minValue;
    }

    @computed get userMaxValue(): number {
        return this._userMaxValue || this.maxValue;
    }

    @computed get ratio(): number {
        return this._userRatio || 100 / (this.maxValue - this.minValue);
    }

    render() {
        const { t } = this.props;

        const eventEls = this.events.map((i) => {
            // lets calculate required values here: left, width
            const styles: CSSProperties = {
                left: `${(i.startTime - this.userMinValue) * this.ratio}%`,
                width: `${(i.endTime - i.startTime) * this.ratio}%`,
            };

            const extraCss =
                i.type === 'offer'
                    ? `gantt-event-${i.type}-${i.data.status} ${(i.data.rejectReason || '')
                          .toLowerCase()
                          .split(/\W+/g)
                          .filter(Boolean)
                          .join('-')}`
                    : '';

            return (
                <div
                    className={`gantt-event gantt-event-${i.type} ${extraCss}`}
                    key={i.id}
                    style={styles}
                    onClick={this.eventClickHandler.bind(this, i)}
                />
            );
        });

        const pingsRangeEls = this.pingsRanges.map((i, k) => {
            // lets calculate required values here: left, width
            const styles: CSSProperties = {
                left: `${(i[0] - this.userMinValue) * this.ratio}%`,
                width: `${(i[1] - i[0]) * this.ratio}%`,
            };

            return <div className="gantt-ping-range" key={k} style={styles} />;
        });

        const tickEls = [this.userMinValue, this.userMaxValue].map((i, ind) => {
            // lets calculate required values here: left, width
            const styles: CSSProperties = {
                left: `${(i - this.userMinValue) * this.ratio}%`,
            };

            return (
                <span className="gantt-tick" style={styles} key={`tick-${ind}`}>
                    {moment(i).format('HH:mm')}
                </span>
            );
        });

        const timelinePointerEl = (
            <div
                className={`gantt-timeline-pointer is-grabbable ${
                    this.pointerDragged && 'is-grabbed'
                }`}
                onMouseDown={this.pointerMouseDownHandler.bind(this)}
                style={{
                    left: `${(this.pointerTimestamp - this.userMinValue) * this.ratio}%`,
                    display: this.pointerTimestamp === 0 ? 'none' : 'block',
                }}
            />
        );

        return (
            <div className="gantt" tabIndex={0} onKeyDown={this.timelineKeyDownHandler.bind(this)}>
                <div
                    ref={this.chartRef}
                    className={`gantt-events is-grabbable ${this.timelineDragged && 'is-grabbed'}`}
                    onWheel={this.timelineWheelHandler.bind(this)}
                    onMouseDown={this.timelineMouseDownHandler.bind(this)}
                    onMouseEnter={this.timelineMouseInHandler.bind(this)}
                    onMouseLeave={this.timelineMouseOutHandler.bind(this)}
                    onClick={this.timelineMouseClickHandler.bind(this)}
                >
                    {eventEls}

                    {pingsRangeEls}

                    {timelinePointerEl}
                </div>

                {tickEls}

                <div
                    className="gantt-mouse-tip"
                    style={{
                        left: `${this.mouseTip.x}px`,
                        display: this.mouseTip.x === 0 ? 'none' : 'block',
                    }}
                >
                    {this.mouseTip.events &&
                        this.mouseTip.events.map((i: GanttEvent) => (
                            <div key={i.id}>
                                <b>{t(...i.genTipLabel())}</b> {i.genTipTimes()}
                            </div>
                        ))}

                    {this.mouseTip.time}
                </div>
            </div>
        );
    }

    getClosestEvent(ts: number): Undef<GanttEvent> {
        return this.events.filter((i) => i.startTime <= ts && i.endTime >= ts).pop();
    }
}

export default withTranslation()(GanttChart);
