import React from 'react';
import { useTranslation } from 'react-i18next';

type ThisComponentProps = {
    logItem: LogItem<any>;
};

const getEventText = (logItem: LogItem<any>, t) => {
    const eventName = logItem.event;
    const eventStr = `log.event.${eventName}`;
    let translated = t(eventStr, { returnObjects: true });

    if (typeof translated === 'object') {
        // Assume that we want the .ok ending when object
        translated = translated.ok || eventStr;
    } else {
        translated = translated;
    }

    // special event types
    if (eventName === 'route.status.ok') {
        return `${translated} (${logItem.parameters?.status || '-'})`
    }
    return translated;
};

const EventTranslator = ({ logItem }: ThisComponentProps) => {
    const { t } = useTranslation();

    return (
        <>{getEventText(
            logItem,
            t
        )}</>
    );
};

export default EventTranslator;
