import './InfoTable.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

type ThisComponentProps = {
    rows: object;
    highlight?: string[];
};

const InfoTable = ({ rows, highlight }: ThisComponentProps) => {
    const { t } = useTranslation();

    return (
        <table className="info-table">
            <tbody>
                {Object.entries(rows)
                    .filter(([k, v]) => Boolean(v))
                    .map(([k, v]) => (
                        <tr
                            key={k}
                            className={`${
                                (highlight || []).includes(k) ? ' info-table-highlight' : ''
                            }`}
                        >
                            <th className="info-table-header">{t(`label.${k}`, k)}</th>
                            {/* No need to show boolean value as Boolean(v) already filtered false */}
                            {typeof v !== 'boolean' && <td>{v}</td>}
                        </tr>
                    ))}
            </tbody>
        </table>
    );
};

export default InfoTable;
