import './CapacitiesList.css';
import { getProp } from '../../utils/utils';

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

type ThisComponentProps = {
    capacities: Capacity[];
    capacityConfs?: Record<string, ILocalizedCapacity>;
};

const icons = {
    1: {
        icon: 'male',
        name: 'ambulatory',
    },
    2: {
        icon: 'wheelchair',
        name: 'wheelchair',
    },
    3: {
        icon: 'suitcase',
        name: 'luggage',
    },
    4: {
        icon: 'walker',
        name: 'assistive-device',
    },
    5: {
        icon: 'stretcher',
        name: 'stretcher',
    },
    6: {
        icon: 'chair',
        name: 'front-seat',
    },
};

function CapacityDisplayValue(props: {cap: Capacity, confs?: Record<string, ILocalizedCapacity>, t: any}) {
    const {cap, confs, t} = props;
    const capConf = icons[cap.unitId]
                    ? icons[cap.unitId]
                    : { icon: 'question', name: cap.unitId };
    const name = t(`capacity.${cap.unitId}`, '') || t(`capacity.${capConf.name}`, capConf.name);

    // decide whether to take icon from display confs or just to show text
    const confDisplayIcon = getProp(confs, [cap.unitId, 'display', 'icon'], null);
    if (confDisplayIcon) {
        return (
            <span
                className="capacity-item"
                key={cap.unitId}
                title={name}
            >
                <FontAwesomeIcon icon={confDisplayIcon} /> {cap.count}
            </span>
        );
    }

    // default conf
    return (
        <span
            className="capacity-item"
            key={cap.unitId}
            title={name}
        >
            <FontAwesomeIcon icon={capConf.icon} /> {cap.count}
        </span>
    );
}

const CapacitiesList = ({ capacities, capacityConfs }: ThisComponentProps) => {
    const { t } = useTranslation();

    return (
        <div className="capacities-list">
            {capacities.map((i) => {
                return <CapacityDisplayValue key={i.unitId} cap={i} confs={capacityConfs} t={t} />;
            })}
        </div>
    );
};

export default CapacitiesList;
