import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RouteModel from '../../models/RouteModel';
import config from '../../config';

const TimelineLink = ({ vehicle, target }: { vehicle: number; target: any }) => {
    const type = target.hasOwnProperty('rejectReason') ? 'offer' : 'route';
    const startTime = type === 'offer' ? target.startTime : (target as RouteModel).stops[0].time;
    const date = moment(startTime).startOf('hour').subtract(4, 'hours').toDate();
    const { rootURL } = config.app;

    return (
        <Link
            className="inline-link"
            to={`${rootURL}vehicle/${vehicle}/${type}/${
                target.id
            }?date=${date.toISOString()}&period=8&pointer=${+moment(startTime)}`}
        >
            {vehicle}
            <i>
                {' '}
                <FontAwesomeIcon icon="external-link-alt" />
            </i>
        </Link>
    );
};

export default TimelineLink;
