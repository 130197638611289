import './VehicleSearchInput.css';

import React from 'react';
import AsyncSelect from 'react-select/async';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import debounce from 'debounce-promise';
import { inject, observer } from 'mobx-react';

const Q_DEBOUNCE = 300;

type ComponentParams = {
    value: Null<IVehicle>;
    onChange: Function;
    vehicles: IVehicle[];
    stores?: any;
};

const isOptionSelected = (o: any, selectedOptions: any[]): boolean => {
    return selectedOptions.length > 0 && selectedOptions[0].number === o.number;
};

const prepareQ = (q: string): string => {
    return q.trim();
};

const getHighlightString = (t: any, v: IVehicle, q): string => {
    return `${t('label.vehicle')} ${v.number}, ${t('label.name')} ${v.name}`;
};

const VehicleSearchInput = ({ value, onChange, vehicles, stores }: ComponentParams) => {
    const { t } = useTranslation();

    const searchVehicles = async (q: string): Promise<IVehicle[]> => {
        return vehicles.filter((v) => {
            return v.name.includes(q) || (v.number + '').includes(q);
        });
    };

    const loadOptions = async (q: string): Promise<IVehicle[]> => {
        q = prepareQ(q);

        if (q.length < 2) {
            return [];
        }

        return searchVehicles(q);
    };

    const debouncedLoadOptions = debounce(loadOptions, Q_DEBOUNCE);

    const formatOptionLabel = (option: IVehicle, { inputValue }: { inputValue: string }) => (
        <Highlighter
            searchWords={[prepareQ(inputValue)]}
            textToHighlight={getHighlightString(t, option, inputValue)}
        />
    );

    const setNoOptionsMessage = ({ inputValue }) => {
        return inputValue === ''
            ? t('input.placeholder.vehiclesSearching')
            : t('input.placeholder.vehiclesNotFound', { q: inputValue });
    };

    return (
        <AsyncSelect
            cacheOptions={false}
            blurInputOnSelect={true}
            placeholder={t('input.placeholder.vehiclesSearch')}
            classNamePrefix="react-select"
            value={value}
            loadOptions={debouncedLoadOptions}
            formatOptionLabel={formatOptionLabel}
            //getOptionLabel={option => `${option.name}, ${option.phone}, ${option.email}`} //option.name
            noOptionsMessage={setNoOptionsMessage}
            isOptionSelected={isOptionSelected}
            onChange={onChange}
        />
    );
};

export default inject('stores')(observer(VehicleSearchInput));
