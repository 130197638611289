import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { inject } from 'mobx-react';
import moment from 'moment';
import InfoTable from '../InfoTable';
import SidePanel from '../SidePanel';
import MainStore from '../../store/mainStore';

interface ThisRouteProps {
    itemId: string;
}

interface ThisComponentProps extends RouteComponentProps<ThisRouteProps> {
    stores: MainStore;
}

const ShiftDetails = ({ match, stores }: ThisComponentProps) => {
    const item: Undef<IShift> = stores.vehicleStore.getShift(+match.params.itemId);

    return item ? (
        <SidePanel>
            <h2>Shift {item.id}</h2>

            <InfoTable
                rows={{
                    vehicle: item.vehicleNumber,
                    startTime: moment(item.startTime).format('L LT'),
                    endTime: moment(item.endTime).format('L LT'),
                    breaks: JSON.stringify(item.breaks),
                }}
            />
        </SidePanel>
    ) : null;
};

export default inject('stores')(ShiftDetails);
