import { action, computed, observable } from 'mobx';
import vehicleApi, { VehicleApi } from './vehicleApi';
import { promisedComputed } from 'computed-async-mobx';
import moment from 'moment';
import RouteModel from '../models/RouteModel';
import PingCluster from '../utils/PingCluster';
import PingModel from '../models/PingModel';

export default class RouteStore {
    private vehicleApi: VehicleApi;

    private pingCluster: PingCluster;

    @observable searchRoute: Undef<number>;

    constructor() {
        this.vehicleApi = vehicleApi;

        this.pingCluster = new PingCluster();
    }

    @action setSearchValues(searchRoute: number) {
        this.setSearchRoute(searchRoute);
    }

    @action setSearchRoute(searchRoute: number) {
        this.searchRoute = searchRoute;
    }

    @computed get routeLogs(): Undef<LogItem<RouteModel>[]> {
        return this.computedRouteLogs.get();
    }

    computedRouteLogs = promisedComputed(undefined, async () => {
        if (this.searchRoute) {
            const logs: Undef<LogItem<RouteModel>[]> = await this.fetchRouteLogs(this.searchRoute);
            const pings: PingModel[] = await this.fetchRoutePings(
                logs && logs.length > 0 ? logs[0].data : undefined
            );

            this.pingCluster.prepare(pings);

            return logs;
        }

        return undefined;
    });

    @computed get offersRounds(): Undef<IOfferRound[]> {
        return this.computedOffersRounds.get();
    }

    computedOffersRounds = promisedComputed(undefined, async () => {
        if (this.searchRoute) {
            const offers = await this.fetchRouteOffers(this.searchRoute);
            const rounds: IOfferRound[] = [];

            offers
                .filter((i) => i.vehicleNumber && i.vehicleNumber > 0) // remove all offers without vehicleNumber
                .forEach((i) => {
                    if (i.vehicleNumber === i.vehicles[0]) {
                        rounds.push({
                            id: rounds.length,
                            startTime: moment(i.startTime).toDate(),
                            vehicles: i.vehicles,
                            offers: [],
                            status: '',
                        });
                    }
                    rounds[rounds.length - 1].offers.push(i);
                    rounds[rounds.length - 1].status = i.status;
                });

            rounds.reverse();

            return rounds;
        }

        return undefined;
    });

    @computed get routePerforms(): Undef<IPerform[]> {
        return this.computedRoutePerforms.get();
    }

    computedRoutePerforms = promisedComputed(undefined, async () => {
        if (this.searchRoute) {
            return await this.fetchRoutePerforms(this.searchRoute);
        }
    });

    @action
    async searchRoutes(q: string): Promise<IRoute[]> {
        return await this.vehicleApi.searchRoutes(q);
    }

    async fetchRouteLogs(routeId): Promise<Undef<LogItem<RouteModel>[]>> {
        const tmp: Undef<LogItem<IRoute>[]> = await this.vehicleApi.getRouteLogs(routeId);
        let logs: Undef<LogItem<RouteModel>[]>;

        if (tmp) {
            // prepare the data
            logs = tmp.map((i) => observable({ ...i, data: new RouteModel(i.data) }));

            // sort the list in REVERSE order
            logs.sort((a, b) => +moment(b.timestamp) - +moment(a.timestamp));
        }

        return logs;
    }

    async fetchRouteOffers(routeId): Promise<IOffer[]> {
        const offers: IOffer[] = await this.vehicleApi.getRouteOffers(routeId);

        offers.sort((a, b) => +moment(a.startTime) - +moment(b.startTime));

        return offers;
    }

    async fetchRoutePings(route: Undef<RouteModel>): Promise<PingModel[]> {
        if (route && route.vehicleNumber) {
            const tmp: Undef<IPing[]> = await this.vehicleApi.getPings(
                route.vehicleNumber,
                moment(route.time.start).toDate(),
                moment(route.time.end).toDate()
            );

            const pings: PingModel[] = (tmp || []).map(
                (i, ind) => new PingModel({ ...i, id: i.id || ind })
            );

            pings.sort((a, b) => a.ts - b.ts);

            return pings;
        }

        return [];
    }

    async fetchClosestPings(vehicles: number[], date: Date): Promise<PingModel[]> {
        const tmp: Undef<IPing[]> = await this.vehicleApi.getClosestPings(vehicles, date);

        return (tmp || []).map(
            (i, ind) => new PingModel({ ...i, id: i.id || ind }, PingModel.TYPE_VEHICLE)
        );
    }

    async fetchRoutePerforms(routeId: number): Promise<IPerform[]> {
        const performs = await this.vehicleApi.getRoutePerforms(routeId);

        return performs.sort(
            (a, b) => new Date(a.serverTime).getTime() - new Date(b.serverTime).getTime()
        );
    }

    getPingsFromTo(from: Date, to: Date): PingModel[] {
        return this.pingCluster.getPath(+moment(from), +moment(to));
    }
}
