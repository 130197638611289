import moment from 'moment';
import PingModel from '../models/PingModel';
import config from '../config';

export default class PingCluster {
    private data: Record<number, Record<number, Record<number, PingModel[]>>> = {};
    private ranges: [number, number][] = [];

    prepare(pings: PingModel[]) {
        this.data = {};
        this.ranges = [];

        pings.forEach((i) => {
            const [day, hour, minute] = PingCluster.genClusterKeys(i.ts);

            if (!this.data[day]) {
                this.data[day] = {};
            }

            if (!this.data[day][hour]) {
                this.data[day][hour] = {};
            }

            if (!this.data[day][hour][minute]) {
                this.data[day][hour][minute] = [];
            }

            this.data[day][hour][minute].push(i);

            if (
                this.ranges.length === 0 ||
                Math.abs(i.ts - this.ranges[this.ranges.length - 1][1]) >
                    config.app.pingExpiration * 1000
            ) {
                this.ranges.push([i.ts, i.ts]);
            } else {
                this.ranges[this.ranges.length - 1][1] = i.ts;
            }
        });
    }

    getClosest(ts: number): Undef<PingModel> {
        let [day, hour, minute] = PingCluster.genClusterKeys(ts);

        try {
            // adjust minute to the closest existing
            minute = PingCluster.getClosestMinute(this.data, day, hour, minute);
            return this.data[day][hour][minute]
                .filter((i) => Math.abs(i.ts - ts) < config.app.pingExpiration * 1000)
                .reduce(
                    (a, i) => (a ? (Math.abs(i.ts - ts) < Math.abs(a.ts - ts) ? i : a) : i),
                    undefined as Undef<PingModel>
                );
        } catch (e) {
            // do nothing, just failed to find an appropriate ping...
        }

        return undefined;
    }

    getRanges(): [number, number][] {
        return this.ranges;
    }

    getPath(fromTs: number, toTs: number): PingModel[] {
        const [sDay, sHour, sMinute] = PingCluster.genClusterKeys(fromTs);
        const [eDay, eHour, eMinute] = PingCluster.genClusterKeys(toTs);

        const pings: PingModel[] = [];

        for (let day = sDay; day <= eDay; day++) {
            const fromHour = day === sDay ? sHour : 0;
            const toHour = day === eDay ? eHour : 23;

            for (let hour = fromHour; hour <= toHour; hour++) {
                const fromMinute = day === sDay && hour === sHour ? sMinute : 0;
                const toMinute = day === eDay && hour === eHour ? eMinute : 59;

                for (let minute = fromMinute; minute <= toMinute; minute++) {
                    try {
                        pings.push(...this.data[day][hour][minute]);
                    } catch (e) {
                        // do nothing, just failed to find an appropriate ping...
                    }
                }
            }
        }

        return pings;
    }

    // find out the closest existing minute of the selected hour from the data object
    // not the perfect solution when the hour start and end times are concerned
    static getClosestMinute(data, day, hour, minute): number {
        if (data[day] && data[day][hour] && !data[day][hour][minute]) {
            const minutes: any[] = Object.keys(data[day][hour]);
            const closest = minutes.reduce(function (prev, curr) {
                return Math.abs(curr - minute) < Math.abs(prev - minute) ? curr : prev;
            }, 1000);
            minute = closest !== 1000 ? closest : minute;
        }
        return minute;
    }

    static genClusterKeys(ts: number): number[] {
        const d = moment.duration(ts);

        return [/*d.years(), d.months(), */ d.days(), d.hours(), d.minutes() /*, d.seconds()*/];
    }
}
