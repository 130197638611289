import './SearchPage.css';

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { action, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { genUrl } from '../../utils/utils';
import MainStore from '../../store/mainStore';
import SidePanel from '../SidePanel';
import RouteModel from '../../models/RouteModel';
import PingModel from '../../models/PingModel';
import SearchInput from '../SearchInput';
import RoutePanel from '../RoutePanel';
import TripPanel from '../TripPanel';
import config from '../../config';

interface ThisRouteProps {
    id: string;
}

interface ThisComponentProps extends RouteComponentProps<ThisRouteProps>, WithTranslation {
    stores: MainStore;
}

@inject('stores')
@observer
class SearchPage extends React.Component<ThisComponentProps, {}> {
    props: any;

    // keep track of currently opened log to draw an appropriate item on the map
    @observable private currentLog: Undef<LogItem<RouteModel>>;
    @observable private currentOffersRound: Undef<IOfferRound>;

    @observable private _searchRoute: string = '';

    @observable private _searchTrip: string = '';

    @observable currentRoute: Null<IRoute> = null;

    @observable currentTrip: Null<ITrip> = null;

    @observable selectedOption: Null<RouteOption | TripOption> = null;

    // trigger the function before closing the component to kill the observer
    private killDataChangeObserver: Undef<Function>;

    urlUpdate() {
        this.currentRoute = null;
        this.currentTrip = null;
        this._searchRoute = '';
        this._searchTrip = '';
        if (this.props.location.pathname.includes('trip')) {
            this._searchTrip = this.props.match.params.id || '';
            this.submitSearchValues();
        } else if (this.props.location.pathname.includes('route')) {
            this._searchRoute = this.props.match.params.id || '';
            this.submitSearchValues();
        }
        /** else search path -> do not submitSearchValues */
    }

    componentDidMount(): void {
        // observe the changes of routeLogs
        this.killDataChangeObserver = reaction(
            () => this.props.location,
            this.urlUpdate.bind(this)
        );
        // fire the initial fetch of the data
        // by submitting search values
        this.urlUpdate();
    }

    componentWillUnmount(): void {
        this.props.stores.uiStore.searchUrl = genUrl(
            this.props.location.pathname,
            this.props.location.search
        );

        // clean the map
        this.props.stores.mapStore.clearMap();

        // kill the observer
        this.killDataChangeObserver && this.killDataChangeObserver();
    }

    // submit the form
    @action submitSearchValues() {
        const { rootURL } = config.app;
        this.props.stores.routeStore.setSearchValues(
            Number.isNaN(+this._searchRoute) ? undefined : +this._searchRoute
        );
        this.props.stores.tripStore.setSearchValues(
            Number.isNaN(+this._searchTrip) ? undefined : +this._searchTrip
        );
        let path = 'search';
        if (+this._searchRoute) {
            path = `${rootURL}route/${this._searchRoute}`;
        }
        if (+this._searchTrip) {
            path = `${rootURL}trip/${this._searchTrip}`;
        }
        const oldPath = genUrl(this.props.location.pathname, this.props.location.search);
        const newPath = genUrl(path, this.props.location.search);

        if (oldPath !== newPath) {
            this.props.history.push(newPath);
        }
    }

    // set/unset the selected log item
    @action setCurrentLog(log: Undef<LogItem<RouteModel>>) {
        this.currentLog = this.currentLog === log ? undefined : log;

        this.props.stores.mapStore.setRoute((this.currentLog || {}).data);
    }

    @action setCurrentRoute(route: Null<IRoute>) {
        this.currentRoute = route;
        this.currentTrip = null;

        this._searchRoute = this.currentRoute ? `${this.currentRoute.id}` : '';
        this._searchTrip = '';
        this.submitSearchValues();
    }

    @action setCurrentTrip(trip: Null<ITrip>) {
        this.currentRoute = null;
        this.currentTrip = trip;

        this._searchRoute = '';
        this._searchTrip = this.currentTrip ? `${this.currentTrip.id}` : '';
        this.submitSearchValues();
    }

    @action onChange(option: Null<RouteOption> | Null<TripOption>) {
        this.selectedOption = option;
        console.log('onChange');
        if (option?.optionType === 'route') {
            const route = option as IRoute;
            this.setCurrentRoute(route);
        }
        if (option?.optionType === 'trip') {
            const trip =
                (option as TripOption).tripWithRoutes[0]?.trip ||
                (option as TripOption).tripNoRoutes;
            this.setCurrentTrip(trip);
        }
    }

    @action async setCurrentOffersRound(round: Undef<IOfferRound>) {
        if (round && round.id !== (this.currentOffersRound || {}).id) {
            this.currentOffersRound = round;

            const routeLogs: Undef<LogItem<RouteModel>[]> = this.props.stores.routeStore.routeLogs;

            const closestLog = (routeLogs || [])
                .filter((i) => moment(i.timestamp) <= moment(round.startTime))
                .pop();

            this.props.stores.mapStore.setRoute((closestLog || {}).data);

            if (!round.pings) {
                round.pings = await this.props.stores.routeStore.fetchClosestPings(
                    round.vehicles,
                    round.startTime
                );
            }

            this.props.stores.mapStore.setPings(round.pings as PingModel[]);
        } else {
            this.currentOffersRound = undefined;
            this.props.stores.mapStore.setPings();
            this.props.stores.mapStore.setRoute();
        }
    }

    highlightPing(round: IOfferRound, vehicleNumber: number, hovered: boolean) {
        const ping = (round.pings || []).find((i) => i.vehicleNumber === vehicleNumber);

        if (ping) {
            (ping as PingModel).hovered = hovered;
        }
    }

    render() {
        const { stores } = this.props;

        const routeLogs = stores.routeStore.routeLogs;
        const tripLogs = stores.tripStore.tripLogs;

        return (
            <div className="search-page">
                <div className="vinka-containers">
                    <div className="vinka-containers-column">
                        <SidePanel className="side-panel-visible vinka-column-head">
                            <div className="form-row">
                                <div className="col-12">
                                    <SearchInput
                                        value={this.selectedOption}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>
                        </SidePanel>

                        {routeLogs && routeLogs.length > 0 && (
                            <RoutePanel
                                stores={this.props.stores}
                                history={this.props.history}
                                location={this.props.location}
                                match={this.props.match}
                            ></RoutePanel>
                        )}
                        {tripLogs && tripLogs.length > 0 && (
                            <TripPanel
                                stores={this.props.stores}
                                history={this.props.history}
                                location={this.props.location}
                                match={this.props.match}
                            ></TripPanel>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SearchPage);
