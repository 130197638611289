import { getI18n } from 'react-i18next';
import moment from 'moment';
import { action, observable } from 'mobx';
import config from '../config';

export default class UiStore {
    @observable lang: string;

    @observable rootUrl: string = config.app.rootURL;
    @observable vehicleUrl: string = config.app.rootURL + 'vehicle';
    @observable historyUrl: string = config.app.rootURL + 'history';
    @observable searchUrl: string = config.app.rootURL + 'search';
    @observable reportUrl: string = config.app.rootURL + 'report';

    constructor() {
        this.lang = getI18n().language;
        moment.locale(this.lang);
    }

    @action setLang(lang: string) {
        getI18n().changeLanguage(lang);
        moment.locale(lang);

        this.lang = lang;
    }
}
