import './VehicleSelect.css';

import React from 'react';
import Select from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

type CommonOption = { name: string; number: number | string };

type ComponentParams = {
    allOption?: CommonOption;
    options: CommonOption[];
    value: string[];
    onChange: Function;
};

const getOptionLabel = (o: CommonOption) =>
    o.number === '*' ? o.name : o.name ? `${o.number} - ${o.name}` : `${o.number}`;

const parseCustomOption = (o: CommonOption): CommonOption[] =>
    `${o.number}`
        .replace(/(\d+)\s+(?=\d)/g, '$1,') // replace all spaces between two numbers with commas
        .replace(/\s+/g, '') // strip ALL spaces/tabs/new lines/etc.
        .split(',') // split into chunks
        .filter((i) => i.match(/^\d+(-\d+)?$/)) // check if matches the pattern `number` or `number-number`
        .map((i) => ({ name: '', number: i })); // convert to an appropriate format

const VehicleSelect = ({ allOption, options, value, onChange }: ComponentParams) => {
    const { t } = useTranslation();

    const setNoOptionsMessage = ({ inputValue }) => {
        return inputValue === ''
            ? t('input.placeholder.vehiclesSearching')
            : t('input.placeholder.vehiclesNotFound', { q: inputValue });
    };

    // TODO: need to come up with a better/ more general solution
    const preOnChange = (e: CommonOption[] | null) => {
        let vehicles: CommonOption[];

        if (!e || e.length === 0) {
            vehicles = [];
        } else if (!allOption) {
            vehicles = e.reduce(
                (a, i) => [...a, ...(i.name ? [i] : parseCustomOption(i))],
                [] as CommonOption[]
            );
        } else if (e[e.length - 1] === allOption) {
            vehicles = [allOption];
        } else {
            vehicles = e
                .filter((i) => i !== allOption)
                .reduce(
                    (a, i) => [...a, ...(i.name ? [i] : parseCustomOption(i))],
                    [] as CommonOption[]
                );
        }

        onChange(vehicles.map((i) => `${i.number}`));
    };

    return (
        <Select
            isClearable={true}
            isMulti={true}
            isSearchable={true}
            closeMenuOnSelect={false}
            hideSelectedOptions={true}
            defaultMenuIsOpen={false}
            placeholder={t('input.placeholder.vehiclesSelect')}
            classNamePrefix="vehicle-select"
            value={value}
            options={allOption ? [allOption, ...options] : options}
            getOptionLabel={getOptionLabel}
            getOptionValue={(o: CommonOption) => `${o.number}`}
            getNewOptionData={(inputValue) => ({
                number: inputValue,
                name: '',
                __isNew__: true,
            })}
            noOptionsMessage={setNoOptionsMessage}
            onChange={preOnChange}
        />
    );
};

export default observer(VehicleSelect);
