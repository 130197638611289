import './VehiclePage.css';

import React, { SyntheticEvent } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import RouteDetails from '../DetailsPanel/RouteDetails';
import OfferDetails from '../DetailsPanel/OfferDetails';
import ShiftDetails from '../DetailsPanel/ShiftDetails';
import BreakDetails from '../DetailsPanel/BreakDetails';
import GanttChart from '../GanttChart';
import { genUrl, getQP } from '../../utils/utils';
import RouteModel from '../../models/RouteModel';
import MainStore from '../../store/mainStore';
import SidePanel from '../SidePanel';
import DateTimePicker from '../DateTimePicker';
import { autorun, computed, observable } from 'mobx';
import { WithTranslation, withTranslation } from 'react-i18next';
import SliderSelect from '../UI/SliderSelect';
import moment from 'moment';

interface ThisRouteProps {
    id: string;
}

interface ThisComponentProps extends RouteComponentProps<ThisRouteProps>, WithTranslation {
    stores: MainStore;
}

@inject('stores')
@observer
class VehiclePage extends React.Component<ThisComponentProps, {}> {
    props: any;

    @observable private _searchVehicle: string = '';
    @observable private _searchDate: Date;
    @observable private _searchPeriod: number;
    @observable private _pointer: Undef<number>;

    private readonly killMapInit: Function;

    constructor(props) {
        super(props);

        const [initialVehicle, initialDate, initialPeriod, initialPointer] = [
            props.match.params.id,
            getQP(props.history.location.search, 'date'),
            getQP(props.history.location.search, 'period'),
            getQP(props.history.location.search, 'pointer'),
        ];

        this._searchVehicle = initialVehicle ? initialVehicle : '';
        this._searchDate = initialDate
            ? new Date(initialDate)
            : moment().set({ hour: 6, minute: 0, second: 0 }).toDate();
        this._searchPeriod = initialPeriod ? +initialPeriod : 8;
        this._pointer = initialPointer ? +initialPointer : undefined;

        // TODO: this should be improved!
        this.killMapInit = autorun(() => {
            const [eventType, eventId] = props.location.pathname.split('/').slice(3);

            if (eventType === 'route' && eventId) {
                const route: Undef<RouteModel> = props.stores.vehicleStore.getRoute(+eventId);

                props.stores.mapStore.setRoute(route);

                props.stores.mapStore.setPingLine(
                    route
                        ? props.stores.vehicleStore.getPingsFromTo(route.time.start, route.time.end)
                        : undefined
                );

                if (this._pointer) {
                    const ping = props.stores.vehicleStore.getClosestPing(this._pointer);

                    props.stores.mapStore.setPing(ping);
                }

                if (route && this.killMapInit) {
                    this.killMapInit();
                }
            }
        });
    }

    componentDidMount(): void {
        this.submitSearchValues(true, true);
    }

    componentWillUnmount(): void {
        this.props.stores.uiStore.vehicleUrl = genUrl(
            this.props.location.pathname,
            this.props.location.search
        );
        this.props.stores.mapStore.clearMap();
        this.killMapInit();
    }

    setPointer(ts: number, event: Undef<{ type: string; id: number }>, isOngoing: boolean = false) {
        const routePath = `${this.props.match.url}${event ? `/${event.type}/${event.id}` : ''}`;

        const oldRouteUrl = genUrl(this.props.location.pathname, this.props.location.search);
        const newRouteUrl = genUrl(routePath, this.props.location.search, { pointer: `${ts}` });

        if (event && event.type === 'route') {
            const route: Undef<RouteModel> = this.props.stores.vehicleStore.getRoute(event.id);

            this.props.stores.mapStore.setRoute(route);

            this.props.stores.mapStore.setPingLine(
                route
                    ? this.props.stores.vehicleStore.getPingsFromTo(
                          route.time.start,
                          route.time.end
                      )
                    : undefined
            );
        } else {
            this.props.stores.mapStore.setRoute(undefined);

            this.props.stores.mapStore.setPingLine(undefined);
        }

        // for `ongoing` we compare only the base part of the url, skipping the query params (to prevent continuous route changes)
        // otherwise compare the full url.
        if (
            this.props.location.pathname !== routePath ||
            (!isOngoing && oldRouteUrl !== newRouteUrl)
        ) {
            this.props.history.replace(newRouteUrl);
            this._pointer = ts;
        }

        const ping = this.props.stores.vehicleStore.getClosestPing(ts);

        this.props.stores.mapStore.setPing(ping);
    }

    setSearchVehicle(e) {
        this._searchVehicle = Number.isNaN(+e.target.value) ? '' : e.target.value;
    }

    setSearchDate(e) {
        this._searchDate = e[0];
    }

    setSearchPeriod(e) {
        this._searchPeriod = e;
    }

    submitSearchValues(
        preservePointer: boolean = false,
        preserveNestedRoute: boolean = false,
        e: Undef<SyntheticEvent> = undefined
    ) {
        if (e) {
            e.preventDefault();
        }

        this.props.stores.mapStore.clearMap();

        this.props.stores.vehicleStore.setSearchValues(
            Number.isNaN(+this._searchVehicle) ? undefined : +this._searchVehicle,
            this._searchDate,
            this._searchPeriod
        );

        if (!preservePointer) {
            this._pointer = undefined;
        }

        const path: string = preserveNestedRoute
            ? this.props.location.pathname
            : this.props.match.path.replace(':id?', this._searchVehicle);

        const [date, period, pointer] = [
            this._searchDate.toISOString(),
            `${this._searchPeriod}`,
            this._pointer ? `${this._pointer}` : undefined,
        ];

        this.props.history.push(
            genUrl(path, this.props.location.search, { date, period, pointer })
        );
    }

    @computed get searchPeriods(): number[] {
        return [4, 8, 12, 16, 20, 24];
    }

    @computed get searchDate(): Date {
        return this._searchDate || this.props.stores.vehicleStore.searchDate;
    }

    @computed get searchPeriod(): number {
        return this._searchPeriod || this.props.stores.vehicleStore.searchPeriod;
    }

    @computed get pingsRanges(): [number, number][] {
        return this.props.stores.vehicleStore.pingsRanges;
    }

    render() {
        const { t } = this.props;

        const periodOptions = this.searchPeriods.map((i) => (
            <option key={i} value={i}>
                {i}
            </option>
        ));

        const vehicleOptions = this.props.stores.vehicleStore.vehicles.map((i) => (
            <option key={i.number} value={i.number}>{`${i.number} ${i.name}`}</option>
        ));

        return (
            <div className="vehicle-page">
                <div className="vinka-containers">
                    <div className="vinka-containers-column">
                        <SidePanel className="vinka-column-head">
                            <form onSubmit={this.submitSearchValues.bind(this, false, false)}>
                                <div className="form-row">
                                    <div className="col-2">
                                        <select
                                            className="form-control form-control-sm"
                                            value={this._searchVehicle}
                                            onChange={this.setSearchVehicle.bind(this)}
                                        >
                                            <option value="">---</option>
                                            {vehicleOptions}
                                        </select>
                                    </div>

                                    <div className="col-4">
                                        <DateTimePicker
                                            value={this._searchDate}
                                            onChange={this.setSearchDate.bind(this)}
                                            enableTime={true}
                                        />
                                    </div>

                                    <div className="col-4 px-3">
                                        {false && (
                                            <select
                                                className="form-control"
                                                value={this._searchPeriod}
                                                onChange={this.setSearchPeriod.bind(this)}
                                            >
                                                {periodOptions}
                                            </select>
                                        )}
                                        <SliderSelect
                                            value={this._searchPeriod}
                                            min={4}
                                            max={24}
                                            step={4}
                                            onChange={this.setSearchPeriod.bind(this)}
                                        />
                                    </div>

                                    <div className="col-2">
                                        <button
                                            type="submit"
                                            className="btn btn-sm btn-secondary btn-block"
                                        >
                                            {t('button.search')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </SidePanel>

                        <Route
                            path={`${this.props.match.path}/route/:itemId`}
                            component={RouteDetails}
                        />
                        <Route
                            path={`${this.props.match.path}/offer/:itemId`}
                            component={OfferDetails}
                        />
                        <Route
                            path={`${this.props.match.path}/shift/:itemId`}
                            component={ShiftDetails}
                        />
                        <Route
                            path={`${this.props.match.path}/break/:itemId`}
                            component={BreakDetails}
                        />
                    </div>
                </div>

                <GanttChart
                    events={this.props.stores.vehicleStore.ganttEvents}
                    pingsRanges={this.pingsRanges}
                    setPointer={this.setPointer.bind(this)}
                    pointerTimestamp={this._pointer}
                    min={+moment(this.props.stores.vehicleStore.searchDate)}
                    max={
                        +moment(this.props.stores.vehicleStore.searchDate).add(
                            this.props.stores.vehicleStore.searchPeriod,
                            'hours'
                        )
                    }
                />
            </div>
        );
    }
}

export default withTranslation()(VehiclePage);
