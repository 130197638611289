import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import InfoTable from '../InfoTable';
import SidePanel from '../SidePanel';
import MainStore from '../../store/mainStore';
import { WithTranslation, withTranslation } from 'react-i18next';
import OfferModel from '../../models/OfferModel';
import RouteInner from './RouteInner';
import { action, observable, reaction } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../config';

interface ThisRouteProps {
    itemId: string;
}

interface ThisComponentProps extends RouteComponentProps<ThisRouteProps>, WithTranslation {
    stores: MainStore;
}

@inject('stores')
@observer
class OfferDetails extends React.Component<ThisComponentProps, {}> {
    props: any;

    @observable item: Undef<OfferModel>;

    private killDataChangeObserver: Undef<Function>;
    private killRoutingObserver: Undef<Function>;

    private rootURL: string = config.app.rootURL;

    // TODO: all those observers and async data fetching should be simplified!
    componentDidMount(): void {
        // tell component to populate itself when initially opened
        this.setItem();

        // observe the changes in the data
        this.killDataChangeObserver = reaction(
            () => this.props.stores.vehicleStore.offers,
            () => this.setItem()
        );

        // observe the changes in the routing
        this.killRoutingObserver = reaction(
            () => this.props.match.params.itemId,
            () => this.setItem()
        );
    }

    componentWillUnmount(): void {
        this.killDataChangeObserver && this.killDataChangeObserver();
        this.killRoutingObserver && this.killRoutingObserver();
    }

    @action
    setItem() {
        this.item = this.props.stores.vehicleStore.getOffer(+this.props.match.params.itemId);

        if (this.item) {
            // if the data exists (has already been fetched), just add the route on the map
            if (this.item.routeLog) {
                this.props.stores.mapStore.setRoute(this.item.actualRoute);
            } else {
                // first fetch logs for the route, then show the route on the map
                this.item
                    .fetchRouteLogs()
                    .then(() => this.props.stores.mapStore.setRoute(this.item!.actualRoute));
            }
        }
    }

    render() {
        const { t } = this.props;

        const item = this.item;

        return item ? (
            <SidePanel>
                <h2>
                    <Link className="inline-link" to={`${this.rootURL}route/${item.routeId}`}>
                        {t('title.offer', { routeId: item.routeId })}{' '}
                        <i>
                            <FontAwesomeIcon icon="external-link-alt" />
                        </i>
                    </Link>
                </h2>

                <InfoTable
                    rows={{
                        offer: item.id,
                        status: `${item.status} ${
                            item.rejectReason ? `(${item.rejectReason})` : ''
                        }`,
                        vehicle: item.vehicleNumber,
                        startTime: moment(item.startTime).format('L LTS'),
                        updateTime: moment(item.updateTime).format('L LTS'),
                        validUntil: moment(item.validUntil).format('L LTS'),
                        offeredTo: item.vehicles.join(', '),
                    }}
                />

                {item.actualRoute && <RouteInner item={item.actualRoute} />}
            </SidePanel>
        ) : null;
    }
}

export default withTranslation()(OfferDetails);
