import './VehicleHistoryPage.css';

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { action, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { genUrl } from '../../utils/utils';
import MainStore from '../../store/mainStore';
import SidePanel from '../SidePanel';
import VehicleSearchInput from '../VehicleSearchInput';
import VehicleHistory from '../VehicleHistory';

interface ThisRouteProps {
    id: string;
    tab: string;
}

interface ThisComponentProps extends RouteComponentProps<ThisRouteProps>, WithTranslation {
    VehicleHistoryPage;
    stores: MainStore;
}

@inject('stores')
@observer
class VehicleHistoryPage extends React.Component<ThisComponentProps, {}> {
    props: any;

    // @observable private currentTab: string = 'vehicle';

    @observable currentVehicle: Null<IVehicle> = null;

    // trigger the function before closing the component to kill the observer
    private killDataChangeObserver: Undef<Function>;

    componentDidMount(): void {
        const vehicleNumber = this.props.match.params.id;
        if (vehicleNumber) {
            this.props.stores.vehicleStore.setVehicleHistorySearch(vehicleNumber);

            const allVehicles = this.props.stores.vehicleStore.vehicles;
            if (allVehicles && allVehicles.length) {
                this.initCurrentVehicle(vehicleNumber); // we have vehicles already
            } else {
                // wait for vehicles to update
                this.killDataChangeObserver = reaction(
                    () => this.props.stores.vehicleStore.vehicles, // need to be getter directly
                    this.initCurrentVehicle.bind(this, vehicleNumber)
                );
            }
        }
    }

    // init e.g. vehicle dropdown value
    initCurrentVehicle(vehicleNumber: number) {
        if (vehicleNumber && !this.currentVehicle) {
            const vehicle = this.props.stores.vehicleStore.vehicles.find(
                (v) => v.number + '' === vehicleNumber + ''
            );
            if (vehicle) {
                this.currentVehicle = vehicle;
            }
        }
        this.killDataChangeObserver && this.killDataChangeObserver(); // no need to run anymore
    }

    componentWillUnmount(): void {
        this.props.stores.uiStore.historyUrl = genUrl(
            this.props.location.pathname,
            this.props.location.search
        );

        // kill the observer
        this.killDataChangeObserver && this.killDataChangeObserver();
    }

    @action setCurrentVehicle(vehicle: Null<IVehicle>) {
        this.currentVehicle = vehicle;
        const vehicleNumber = (vehicle || {}).number;
        // set vehicle number search values so that computed prop gets re-evaluated
        this.props.stores.vehicleStore.setVehicleHistorySearch(vehicleNumber);

        let path: string = this.props.match.path.replace(':tab?', 'vehicle');
        path = path.replace(':id?', vehicleNumber ? vehicleNumber + '' : '');
        const oldPath = genUrl(this.props.location.pathname, this.props.location.search);
        const newPath = genUrl(path, this.props.location.search);
        if (oldPath !== newPath) {
            this.props.history.push(newPath);
        }
    }

    render() {
        const { t, stores } = this.props;
        const vehicles = stores.vehicleStore.vehicles;
        return (
            <div className="vehicle-history-page">
                <div className="vinka-containers">
                    <div className="vinka-containers-column">
                        <SidePanel className="side-panel-visible vinka-column-head">
                            <div className="form-row">
                                <div className="col-12">
                                    <h2>{t('title.vehicle')}</h2>

                                    {
                                        <VehicleSearchInput
                                            value={this.currentVehicle}
                                            onChange={this.setCurrentVehicle.bind(this)}
                                            vehicles={vehicles}
                                        />
                                    }
                                </div>
                            </div>
                        </SidePanel>
                        {
                            <VehicleHistory
                                stores={this.props.stores}
                                vehicleId={(this.currentVehicle || {}).number}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(VehicleHistoryPage);
