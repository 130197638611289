import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import MainStore from '../../store/mainStore';

type ThisComponentProps = {
    stores?: MainStore;
    date: Date | null;
    showTime?: boolean;
    showDate?: boolean;
};

const TimeView = ({ stores, date, showTime = true, showDate = false }: ThisComponentProps) => (
    <span>{date ? moment(date).format(`${showTime ? 'LT' : ''}${showDate ? ' L' : ''}`) : ''}</span>
);

export default inject('stores')(observer(TimeView));
