import React from 'react';
import { observer } from 'mobx-react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.icon.glyph';

type ThisComponentProps = {
    perform: ICompletion;
};

const PerformMarker = ({ perform }: ThisComponentProps) => {
    // @ts-ignore
    const icon = L.icon.glyph({ prefix: '', glyph: '✓', glyphColor: '#ffffff', glyphSize: '20px' });

    return <Marker icon={icon} type={'perform'} position={perform.coordinates} />;
};

export default observer(PerformMarker);
