import moment from 'moment';
import { computed, observable } from 'mobx';

export default class PingModel implements IPing {
    static readonly TYPE_TS = 'ts';
    static readonly TYPE_VEHICLE = 'vehicle';

    id: number;
    vehicleNumber: number;
    routeId: Null<number>;
    lastTaskId: any;
    vehicleTime: Date;
    serverTime: Date;
    gps: GPS;

    readonly type: string;

    @observable hovered: boolean = false;

    constructor(ping: IPing, type: string = PingModel.TYPE_TS) {
        this.id = ping.id!;
        this.vehicleNumber = ping.vehicleNumber;
        this.routeId = ping.routeId;
        this.lastTaskId = ping.lastTaskId;
        this.vehicleTime = ping.vehicleTime;
        this.serverTime = ping.serverTime;
        this.gps = ping.gps;

        this.type = type;
    }

    @computed get ts(): number {
        return +moment(this.serverTime);
    }

    @computed get label(): string {
        return this.type === PingModel.TYPE_VEHICLE
            ? `${this.vehicleNumber}`
            : moment(this.serverTime).format('LT');
    }

    @computed get isLabelPermanent(): boolean {
        return this.type === PingModel.TYPE_VEHICLE;
    }
}
