import React from 'react';
import { observer } from 'mobx-react';
import InfoTable from '../InfoTable';
import Itinerary from '../Itinerary';
import TripModel from '../../models/TripModel';
import { WithTranslation, withTranslation } from 'react-i18next';
import { genFormattedAddress } from '../../utils/utils';
import { Link } from 'react-router-dom';
import TimelineLink from '../UI/TimelineLink';
import moment from 'moment';
import config from '../../config';

interface ThisComponentProps extends WithTranslation {
    item: TripModel;
    log?: any; // Route event log object
    showVehicleLink?: boolean;
}

@observer
class TripInner extends React.Component<ThisComponentProps, {}> {
    props: any;

    eventDetails(tripLog, t) {
        console.log(tripLog);
        if (!tripLog) {
            return null;
        }
        const ret: any = {};

        // target route for event
        // trip cancel reason
        if (tripLog.cancelReason) {
            // found from route log parameters
            ret.tripCancelDescription = `${t(
                `trip.cancelReason.${tripLog.cancelReason}`,
                tripLog.cancelReason
            )}${tripLog.cancelDescription ? `: ${tripLog.cancelDescription}` : ''}`;
        }

        return Object.keys(ret).length ? ret : null;
    }

    render() {
        const { t } = this.props;
        const { rootURL } = config.app;

        const tripLog: LogItem<TripModel> = this.props.log;
        const eventDetails = this.eventDetails(tripLog, t);
        const item: TripModel = this.props.item;

        const vehicleNumber = item.route?.vehicleNumber ? (
            this.props.showVehicleLink ? (
                <TimelineLink vehicle={item.route.vehicleNumber} target={item.route} />
            ) : (
                item.route.vehicleNumber
            )
        ) : null;
        return (
            <>
                <InfoTable
                    rows={{
                        vehicle: vehicleNumber,
                        externalId: item.externalId,
                        pick: genFormattedAddress(item.start),
                        drop: genFormattedAddress(item.end),
                        requestedTime:
                            t(`label.${item.anchor}`) +
                            ' ' +
                            moment(item.requestedTime).format('L LT'),
                        actorUser: tripLog?.actor?.username || '',
                        actorClient: tripLog?.actor?.client || '',
                    }}
                />

                {eventDetails && (
                    <>
                        <h5 className="mt-3">{t('label.eventDetails')}</h5>
                        <InfoTable rows={eventDetails} />
                    </>
                )}

                {item.routeId && (
                    <>
                        <h5 className="mt-3">
                            {t('label.route')}{' '}
                            <Link className="inline-link" to={`${rootURL}route/${item.routeId}`}>
                                {item.routeId}
                            </Link>
                        </h5>

                        <Itinerary stops={item.route?.stops || item.stops} />
                    </>
                )}
            </>
        );
    }
}

export default withTranslation()(TripInner);
