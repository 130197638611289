import 'leaflet/dist/leaflet.css';
import './BackgroundMap.css';

import React, { createRef } from 'react';
import { LayerGroup, Map, TileLayer, Polyline } from 'react-leaflet';
import L, { FitBoundsOptions, LatLngBounds } from 'leaflet';
import { inject, observer } from 'mobx-react';
import StopMarker from './StopMarker';
import PingLine from './PingLine';
import PingMarker from './PingMarker';
import PingModel from '../../models/PingModel';
import config from '../../config';
import PerformMarker from './PerformMarker';

let prot: any = L.Icon.Default.prototype;
delete prot._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

@inject('stores')
@observer
export default class BackgroundMap extends React.Component {
    private centerMapTimeout: Null<number> = null;

    props: any;
    mapRef: any;
    lat: number;
    lng: number;
    zoom: number;

    constructor(props) {
        super(props);
        this.mapRef = createRef();
        this.lat = config.map.center[0] || 61.497752;
        this.lng = config.map.center[1] || 23.860954;
        this.zoom = 12;
    }

    debounceCenterMap() {
        if (this.centerMapTimeout) {
            window.clearTimeout(this.centerMapTimeout);
        }
        this.centerMapTimeout = window.setTimeout(this.centerMap.bind(this), 100);
    }

    centerMap() {
        const map: any = this.mapRef.current;

        if (map !== null) {
            const newBounds: LatLngBounds[] = [];
            const boundsOptions: FitBoundsOptions = {
                paddingTopLeft: [540, 50],
                paddingBottomRight: [0, 80],
            };

            map.leafletElement.eachLayer((layer) => {
                if (layer.options.type === 'pings') {
                    const points = layer
                        .getLayers()
                        .map((i) => i.getLatLng())
                        .filter((i) => Number.isFinite(i.lat) && Number.isFinite(i.lng));

                    if (points.length > 1) {
                        newBounds.push(L.latLngBounds(points));
                    } else if (points.length === 1) {
                        newBounds.push(points[0].toBounds(50));
                        boundsOptions.maxZoom = map.leafletElement.getZoom();
                    }
                }

                if (['trip'].includes(layer.options.type)) {
                    newBounds.push(layer.getBounds());
                }
            });

            if (newBounds.length > 0) {
                const bounds: LatLngBounds = newBounds[0];
                newBounds.forEach((i) => bounds.extend(i));

                map.leafletElement.fitBounds(bounds, boundsOptions);
            }
        }
    }

    render() {
        return (
            <Map
                ref={this.mapRef}
                useFlyTo={true}
                className="background-map"
                center={[this.lat, this.lng]}
                zoom={this.zoom}
                style={{ width: '100%', height: '100%' }}
                zoomControl={false}
                maxZoom={19}
            >
                <TileLayer url={config.map.tilesUrl} apikey={config.map.key} />

                <LayerGroup type={'pings'}>
                    {this.props.stores.mapStore.pings.map((ping) => (
                        <PingMarker
                            key={`ping-${ping.id}`}
                            ping={ping}
                            centerMap={this.debounceCenterMap.bind(this)}
                        />
                    ))}
                </LayerGroup>

                <LayerGroup type={'pingLines'}>
                    {this.props.stores.mapStore.pingLines.map((pings: PingModel[], ind) => (
                        <PingLine key={`pingLine-${ind}`} pings={pings} />
                    ))}
                </LayerGroup>

                <LayerGroup type={'routes'}>
                    {this.props.stores.mapStore.routes.map((route) => (
                        <LayerGroup type={'route'} key={route.id}>
                            <LayerGroup type={'routeTrips'} key={`routeTrips-${route.id}`}>
                                {route.trips.map((t) => (
                                    <Polyline
                                        type={'trip'}
                                        key={`trip-${t.id}`}
                                        positions={t.pathPoints}
                                        onAdd={this.debounceCenterMap.bind(this)}
                                        color={t.hovered ? 'blue' : 'black'}
                                        weight={t.hovered ? 4 : 2}
                                    />
                                ))}
                            </LayerGroup>

                            <LayerGroup type={'routeStops'} key={`routeStops-${route.id}`}>
                                {route.stops.map((s) => (
                                    <StopMarker key={`stop-${s.id}`} stop={s} />
                                ))}
                            </LayerGroup>
                        </LayerGroup>
                    ))}
                </LayerGroup>

                <LayerGroup type={'performs'}>
                    {this.props.stores.mapStore.performs.map((i, k) => (
                        <PerformMarker key={`perform-${k}`} perform={i} />
                    ))}
                </LayerGroup>
            </Map>
        );
    }
}
