import './Header.css';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import MainStore from '../../store/mainStore';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../../react-auth0-spa';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../config';
import { version as appVersion } from '../../../package.json';

type ThisComponentProps = {
    stores?: MainStore;
};

const langs = config.ui.languages;

const Header = ({ stores }: ThisComponentProps) => {
    const { t } = useTranslation();
    const { loading, isAuthenticated, loginWithPopup, logout } = useAuth0();

    const changeLanguage = (id) => stores!.uiStore.setLang(id);
    // set customer replacement resources (note this can be called couple of times while rendering)
    stores?.configStore.replaceCustomerResources();

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-inverse">
            <Link className="navbar-brand vinka-brand" to={stores!.uiStore.rootUrl}>
                {t('header.title')}
            </Link>

            <ul className="navbar-nav mr-auto">
                {isAuthenticated && (
                    <>
                        {false && (
                            <li className="nav-item">
                                <Link className="nav-link" to={stores!.uiStore.rootUrl}>
                                    {t('header.link.home')}
                                </Link>
                            </li>
                        )}
                        <li className="nav-item">
                            <Link className="nav-link" to={stores!.uiStore.vehicleUrl}>
                                {t('header.link.vehicle')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={stores!.uiStore.searchUrl}>
                                {t('header.link.search')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={stores!.uiStore.historyUrl}>
                                {t('header.link.history')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={stores!.uiStore.reportUrl}>
                                {t('header.link.report')}
                            </Link>
                        </li>
                    </>
                )}
            </ul>

            <div className={'form-inline'}>
                {!loading && !isAuthenticated && (
                    <button
                        className={'btn btn-sm btn-outline-light mr-sm-2'}
                        onClick={() => loginWithPopup!({})}
                    >
                        {t('button.login')}
                    </button>
                )}

                <NavDropdown
                    className="vinka-settings"
                    title={<FontAwesomeIcon icon="cog" />}
                    id="basic-nav-dropdown"
                >
                    <NavDropdown.Header>v.{appVersion}</NavDropdown.Header>

                    <NavDropdown.Divider />
                    {langs.map((i) => (
                        <NavDropdown.Item key={i.id} onClick={() => changeLanguage(i.id)}>
                            {i.name}
                        </NavDropdown.Item>
                    ))}

                    {!loading && isAuthenticated && (
                        <>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={() => logout!({})}>
                                {t('button.logout')}
                            </NavDropdown.Item>
                        </>
                    )}
                </NavDropdown>
            </div>
        </nav>
    );
};

export default inject('stores')(observer(Header));
