import moment from 'moment';

export default class GanttEvent implements IGanttEvent {
    id: string;
    type: ItemType;
    data: any;

    // these values are used to display the event on the timeline and may differ from the actual values of the event
    startTime: number;
    endTime: number;

    // these are actual values of the event
    realStartTime: number;
    realEndTime: number;

    constructor(type: ItemType, data: any) {
        this.type = type;
        this.data = data;

        this.id = `${this.type}-${this.data.id}`;

        [this.realStartTime, this.realEndTime] = [this.startTime, this.endTime] = this.genTimes();

        // the difference between start and end time for the offer is SO small, that it is almost impossible to hover it with the mouse
        if (this.type === 'offer') {
            this.endTime = this.startTime + 60000;
        }
    }

    genTimes(): [number, number] {
        switch (this.type) {
            case 'route':
                return [+moment(this.data.time.start), +moment(this.data.time.end)];
            case 'shift':
                return [+moment(this.data.startTime), +moment(this.data.endTime)];
            case 'break':
                return GanttEvent.genBreakStartEndTs(this.data);
            case 'offer':
                return [+moment(this.data.startTime), +moment(this.data.updateTime)];

            default:
                return [Number.NaN, Number.NaN];
        }
    }

    genTipLabel(): [string, object] | [string] {
        switch (this.type) {
            case 'route':
                return [`title.route`, { id: this.data.id }];
            case 'offer':
                return [`label.offerFor`, { id: this.data.routeId }];

            default:
                return [`label.${this.type}`];
        }
    }

    genTipTimes(): string {
        switch (this.type) {
            case 'offer':
                return `${moment(this.realStartTime).format('LTS')}-${moment(
                    this.realEndTime
                ).format('LTS')}`;

            default:
                return `${moment(this.realStartTime).format('LT')}-${moment(
                    this.realEndTime
                ).format('LT')}`;
        }
    }

    static genBreakStartEndTs(b: IBreak): [number, number] {
        if (b.completion) {
            if (b.completion.status === 'started') {
                return [
                    +moment(b.completion.timestamp!),
                    +moment(b.completion.timestamp!).add(b.duration, 'seconds'),
                ];
            }

            if (b.completion.status === 'finished') {
                return [
                    +moment(b.completion.timestamp!).subtract(b.duration, 'seconds'),
                    +moment(b.completion.timestamp!),
                ];
            }
        }

        if (b.estimatedStart && b.estimatedEnd) {
            return [+moment(b.estimatedStart), +moment(b.estimatedEnd)];
        }

        return [+moment(b.plannedStart), +moment(b.plannedEnd)];
    }
}
