import { action, computed, observable } from 'mobx';
import vehicleApi, { VehicleApi } from './vehicleApi';
import { promisedComputed } from 'computed-async-mobx';
import moment from 'moment';
import TripModel from '../models/TripModel';
import RouteModel from '../models/RouteModel';

export default class TripStore {
    private vehicleApi: VehicleApi;

    @observable searchTrip: Undef<number>;

    constructor() {
        this.vehicleApi = vehicleApi;
    }

    @action setSearchValues(searchTrip: number) {
        this.setSearchRoute(searchTrip);
    }

    @action setSearchRoute(searchTrip: number) {
        this.searchTrip = searchTrip;
    }

    @computed get tripLogs(): Undef<LogItem<TripModel>[]> {
        return this.computedTripLogs.get();
    }

    computedTripLogs = promisedComputed(undefined, async () => {
        if (this.searchTrip) {
            const logs: Undef<LogItem<TripModel>[]> = await this.fetchTripLogs(this.searchTrip);

            return logs;
        }

        return undefined;
    });

    @action
    async searchTrips(q: string): Promise<ITrip[]> {
        return await this.vehicleApi.searchTrips(q);
    }

    async fetchTripLogs(tripId): Promise<Undef<LogItem<TripModel>[]>> {
        const tmp: Undef<LogItem<ITrip>[]> = await this.vehicleApi.getTripLogs(tripId);
        let logs: Undef<LogItem<TripModel>[]>;

        if (tmp) {
            // prepare the data
            logs = tmp.map((i) =>
                observable({
                    ...i,
                    data: new TripModel(i.route?.stops ? new RouteModel(i.route) : null, i.data),
                })
            );

            // sort the list in REVERSE order
            logs.sort((a, b) => +moment(b.timestamp) - +moment(a.timestamp));
        }
        console.log(logs);
        return logs;
    }
}
