import 'flatpickr/dist/flatpickr.css';

import { Finnish } from 'flatpickr/dist/l10n/fi.js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import 'moment/locale/fi';
import { observer } from 'mobx-react';

type ThisComponentProps = {
    enableTime?: boolean;
    value: Date | any[]; // can be single date for the single selection or array of Date/undefined for range
    minDate?: Date;
    maxDate?: Date | string;
    pickerType?: string;
    maxRange?: number;
    onChange: Function;
};

const DateTimePicker = ({
    enableTime,
    value,
    minDate,
    maxDate,
    pickerType,
    maxRange,
    onChange,
}: ThisComponentProps) => {
    const { i18n } = useTranslation();

    const locale =
        i18n.language === 'fi' ? Object.assign({}, Finnish, { rangeSeparator: ' - ' }) : null;

    const format = Boolean(enableTime) ? 'L LT' : 'L';

    return (
        <Flatpickr
            key={locale}
            className="form-control form-control-sm"
            data-enable-time={Boolean(enableTime)}
            // value={value}
            onChange={onChange}
            options={{
                mode: pickerType === 'range' ? 'range' : 'single',
                minDate: minDate || null,
                maxDate: maxDate || null,
                locale: locale,
                defaultDate: value,
                formatDate: (e) => moment(e).locale(i18n.language).format(format),
                enable: [
                    function (date) {
                        // return true to enable
                        if (pickerType === 'range' && Array.isArray(value) && maxRange) {
                            if (
                                value.length === 2 &&
                                value[0] !== undefined &&
                                value[1] === undefined
                            ) {
                                return (
                                    Math.abs(date.getTime() - value[0].getTime()) <
                                    1000 * 60 * 60 * 24 * maxRange
                                );
                            }
                        }
                        return true;
                    },
                ],
            }}
        />
    );
};
export default observer(DateTimePicker);
