import '../SortTable/SortTable.css';

import React from 'react';
import { observer } from 'mobx-react';
import { useTable, useSortBy } from 'react-table';

interface ThisComponentProps {
    id?: string;
    columns: any[];
    data: Record<string, string | number>[];
    onRowMouseEnter?: (row: any) => any;
    onRowMouseLeave?: (row: any) => any;
}

const SortTable = ({ id, data, columns, onRowMouseEnter, onRowMouseLeave }: ThisComponentProps) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useSortBy
    );

    return (
        <table id={id} className="sort-table" {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            onMouseEnter={() => onRowMouseEnter && onRowMouseEnter(row)}
                            onMouseLeave={() => onRowMouseLeave && onRowMouseLeave(row)}
                        >
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default observer(SortTable);
