import * as polyUtil from 'polyline-encoded';
import { computed } from 'mobx';
import StopModel from './StopModel';
import TripModel from './TripModel';

export default class RouteModel implements IRoute {
    id: number;
    vehicleNumber: number | null;
    ordinal: number;
    forcedOrdinal: boolean;
    batchId: any;
    startTime: Date;
    endTime: Date;
    trips: TripModel[];
    metrics: RouteMetrics;
    type: string;
    externalId: any;
    price?: IPrice;
    stops: StopModel[];
    archived?: boolean;

    constructor(route: IRoute) {
        this.id = route.id;
        this.vehicleNumber = route.vehicleNumber;
        this.ordinal = route.ordinal;
        this.forcedOrdinal = route.forcedOrdinal;
        this.batchId = route.batchId;
        this.startTime = route.startTime;
        this.endTime = route.endTime;
        this.trips = route.trips.map((i) => new TripModel(this, i));
        this.metrics = route.metrics;
        this.type = route.type;
        this.externalId = route.externalId;
        this.price = route.price;
        this.archived = route.archived;

        // Not all stops have trips. Make trips easily accessible by stopId for those stops that do.
        const stopTripMap: { [stopId: string]: TripModel } = {};
        for (const trip of this.trips) {
            stopTripMap[String(trip.ride.pick.id)] = trip;
            stopTripMap[String(trip.ride.drop.id)] = trip;
        }

        this.stops = route.stops.map(
            (stop) => new StopModel(stop, this, stopTripMap[String(stop.id)])
        );
    }

    @computed.struct get time(): { start: Date; end: Date } {
        // Routes can have 0 stops, e.g. if it is a service route from a timetable of only areas.
        return {
            start: this.stops[0]?.time || this.startTime,
            end: this.stops[this.stops.length - 1]?.time || this.endTime,
        };
    }

    @computed.struct get pathPoints(): ICoords[] {
        return this.stops.reduce((a, i) => [...a, ...polyUtil.decode(i.gpath)], [] as ICoords[]);
    }
}
