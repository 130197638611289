import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

type ThisComponentProps = {
    value: number;
};

//const pad = (n: number): string => `${n}`.padStart(2, '0');

const Seconds = ({ value }: ThisComponentProps) => {
    const { t } = useTranslation();

    const d = moment.duration(value, 'seconds');

    const [days, hours, minutes, seconds] = [d.days(), d.hours(), d.minutes(), d.seconds()];

    // ignore seconds if hours are available.
    return (
        <span>
            {days > 0 && <span> {t('time.placeholder.d', { value: days })}</span>}
            {hours > 0 && <span> {t('time.placeholder.h', { value: hours })}</span>}
            {minutes > 0 && <span> {t('time.placeholder.m', { value: minutes })}</span>}
            {hours === 0 && (seconds > 0 || minutes === 0) && (
                <span> {t('time.placeholder.s', { value: seconds })}</span>
            )}
        </span>
    );
};

export default observer(Seconds);
