import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import InfoTable from '../InfoTable';
import Itinerary from '../Itinerary';
import RouteModel from '../../models/RouteModel';
import { WithTranslation, withTranslation } from 'react-i18next';
import Seconds from '../Humanize/Seconds';
import Meters from '../Humanize/Meters';
import GridTable from '../GridTable';
import TimelineLink from '../UI/TimelineLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ThisComponentProps extends WithTranslation {
    item: RouteModel;
    log?: any; // Route event log object
    showVehicleLink?: boolean;
}

@observer
class RouteInner extends React.Component<ThisComponentProps, {}> {
    props: any;

    @observable private metricsShown: boolean = false;

    @action private toggleMetrics() {
        this.metricsShown = !this.metricsShown;
    }

    eventDetails(routeLog, t) {
        if (!routeLog) {
            return null;
        }

        const logDetails = routeLog.details || {};
        const ret: any = {};

        // target route for event
        const eventParams = routeLog.parameters || {};
        // actual route id that caused the event to occur
        if (eventParams.routeId && eventParams.routeId !== routeLog.routeId) {
            ret.eventTargetRoute = eventParams.routeId;
        }

        // trip cancel reason
        if (eventParams.cancelReason) {
            // found from route log parameters
            ret.tripCancelDescription = `${t(
                `trip.cancelReason.${eventParams.cancelReason}`,
                eventParams.cancelReason
            )}${eventParams.cancelDescription ? `: ${eventParams.cancelDescription}` : ''}`;
        } else if (logDetails.tripCancelReason) {
            // resolved from trip event logs
            ret.tripCancelDescription = `${t(
                `trip.cancelReason.${logDetails.tripCancelReason}`,
                logDetails.tripCancelReason
            )}${logDetails.tripCancelDescription ? `: ${logDetails.tripCancelDescription}` : ''}`;
        }

        return Object.keys(ret).length ? ret : null;
    }

    render() {
        const { t } = this.props;

        const routeLog: LogItem<RouteModel> = this.props.log;
        const eventDetails = this.eventDetails(routeLog, t);
        const item: RouteModel = this.props.item;

        const vehicleNumber = item.vehicleNumber ? (
            this.props.showVehicleLink ? (
                <TimelineLink vehicle={item.vehicleNumber} target={item} />
            ) : (
                item.vehicleNumber
            )
        ) : null;

        return (
            <>
                <InfoTable
                    rows={{
                        vehicle: vehicleNumber,
                        externalId: item.externalId,
                        type: item.type,
                        price: item.price ? `${item.price.total} ${item.price.currency}` : null,
                        actorUser: routeLog?.actor?.username || '',
                        actorClient: routeLog?.actor?.client || '',
                        archived: item.archived,
                    }}
                />

                {eventDetails && (
                    <>
                        <h5 className="mt-3">{t('label.eventDetails')}</h5>
                        <InfoTable rows={eventDetails} />
                    </>
                )}

                <h5 className="mt-3">{t('label.itinerary')}</h5>

                <Itinerary stops={item.stops} />

                <h5 className="mt-3">
                    <span onClick={this.toggleMetrics.bind(this)}>
                        <FontAwesomeIcon
                            icon={this.metricsShown ? 'minus-square' : 'plus-square'}
                        />{' '}
                        {t('label.metrics')}
                    </span>
                </h5>

                {this.metricsShown && (
                    <GridTable
                        rows={[
                            ['', t('metric.length'), t('metric.duration')],
                            [
                                t('metric.total'),
                                <Meters value={item.metrics.length} />,
                                <Seconds value={item.metrics.duration} />,
                            ],
                            [
                                t('metric.direct'),
                                <Meters value={item.metrics.directDistance} />,
                                <Seconds value={item.metrics.directTime} />,
                            ],
                            [
                                t('metric.service'),
                                <Meters value={item.metrics.serviceDistance} />,
                                <Seconds value={item.metrics.serviceTime} />,
                            ],
                            [
                                t('metric.empty'),
                                <Meters value={item.metrics.emptyDistance} />,
                                <Seconds value={item.metrics.emptyTime} />,
                            ],
                            [t('metric.loading'), '', <Seconds value={item.metrics.loadingTime} />],
                            [
                                t('metric.unloading'),
                                '',
                                <Seconds value={item.metrics.unloadingTime} />,
                            ],
                        ]}
                    />
                )}
            </>
        );
    }
}

export default withTranslation()(RouteInner);
