import './GridTable.css';

import React from 'react';

type ThisComponentProps = {
    rows: any[][];
};

const GridTable = ({ rows }: ThisComponentProps) => {
    return (
        <table className="grid-table">
            <tbody>
                {rows.map((cols, ri) => (
                    <tr key={ri}>
                        {cols.map((cell, ci) => (
                            <td key={ci} className={ci === 0 || ri === 0 ? 'header' : ''}>
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default GridTable;
