import React from 'react';
import { observer } from 'mobx-react';
import { Marker, Tooltip } from 'react-leaflet';
import PingModel from '../../models/PingModel';

type ThisComponentProps = {
    ping: PingModel;
    centerMap: Function;
};

const PingMarker = ({ ping, centerMap }: ThisComponentProps) => {
    return (
        <Marker
            type={'ping'}
            position={[ping.gps.lat, ping.gps.lng]}
            onAdd={centerMap}
            opacity={!ping.isLabelPermanent || ping.hovered ? 1 : 0.5}
            zIndexOffset={ping.hovered ? 5000 : 0}
        >
            <Tooltip
                className={`leaflet-tooltip-tiny ${
                    ping.isLabelPermanent ? `leaflet-tooltip-opaque` : ``
                } ${ping.hovered ? `leaflet-tooltip-hovered` : ``}`}
                permanent={ping.isLabelPermanent}
                direction={'top'}
                pane={'markerPane'}
            >
                {ping.label}
            </Tooltip>
        </Marker>
    );
};

export default observer(PingMarker);
