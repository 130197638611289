import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import LoadSpinner from '../UI/LoadSpinner';
import SortTable from '../SortTable/SortTable';
import moment from 'moment';
import MainStore from '../../store/mainStore';

interface PerformTableProps {
    performs?: IPerform[];
    stores: MainStore;
}

export default observer(function PerformTable(props: PerformTableProps) {
    const { t } = useTranslation();

    if (!props.performs) {
        return <LoadSpinner />;
    }

    const columns = [
        {
            Header: t('performTable.headers.time'),
            accessor: 'serverTime',
            Cell: ({ cell: { value } }) => {
                // Localized time with seconds and date
                return moment(value).format('LTS L');
            },
        },
        {
            Header: t('performTable.headers.type'),
            accessor: 'itemType',
            Cell: ({ cell: { value } }) => {
                // If no translation, just use the value, e.g. stop-pick
                return <span>{t([`performTable.itemTypes.${value}`, value])}</span>;
            },
        },
        {
            Header: t('performTable.headers.status'),
            accessor: 'status',
            Cell: ({ cell: { value } }) => {
                // If no translation, just use the value, e.g. performed
                return <span>{t([`performTable.statuses.${value}`, value])}</span>;
            },
        },
    ];

    const onPerformHover = (perform?: IPerform) => {
        props.stores.mapStore.setPerform(
            perform && {
                coordinates: perform.gps,
                timestamp: new Date(perform.serverTime),
                status: perform.status,
            }
        );
    };

    return (
        <SortTable
            id="perform-table"
            data={props.performs as any}
            columns={columns}
            onRowMouseEnter={(row) => onPerformHover(row.original)}
            onRowMouseLeave={() => onPerformHover()}
        />
    );
});
