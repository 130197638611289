import './LabelsList.css';

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

type ThisComponentProps = {
    labels: string[];
};

const icons = {
    'app': {
        icon: 'mobile-alt'
    },
};

function LabelDisplayValue(props: {label: string, t: any}) {
    const {label, t} = props;

    if (icons[label]) {
        return (<span
            className="labels-item"
            key={label}
            title={label}
        >
            <FontAwesomeIcon icon={icons[label].icon} />
        </span>);
    }

    return (<></>);
}

const LabelsList = ({ labels }: ThisComponentProps) => {
    const { t } = useTranslation();

    return (
        <div className="labels-list">
            {labels.map((i) => {
                return <LabelDisplayValue key={i} label={i} t={t} />;
            })}
        </div>
    );
};

export default LabelsList;
