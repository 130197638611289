import './TripPanel.css';

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Accordion, Card, Tabs, Tab } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { action, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import MainStore from '../../store/mainStore';
import SidePanel from '../SidePanel';
import TripModel from '../../models/TripModel';
import TripInner from '../DetailsPanel/TripInner';
import EventSelect, { filterByEvents } from '../EventSelect';
import EventTranslator from '../DetailsPanel/EventTranslator';

interface ThisRouteProps {
    id: string;
}

interface ThisComponentProps extends RouteComponentProps<ThisRouteProps>, WithTranslation {
    stores: MainStore;
}

@inject('stores')
@observer
class TripPanel extends React.Component<ThisComponentProps, {}> {
    props: any;

    // keep track of currently opened log to draw an appropriate item on the map
    @observable private currentLog: Undef<LogItem<TripModel>>;

    @observable private currentTab: string = 'logs';
    @observable selectedEvents: string[] = [];

    // trigger the function before closing the component to kill the observer
    private killDataChangeObserver: Undef<Function>;

    componentDidMount(): void {
        // observe the changes of routeLogs
        this.killDataChangeObserver = reaction(
            () => this.props.stores.tripStore.tripLogs,
            this.dataChangeObserver.bind(this)
        );
        this.dataChangeObserver();
    }

    componentWillUnmount(): void {
        // clean the map
        this.props.stores.mapStore.clearMap();

        // kill the observer
        this.killDataChangeObserver && this.killDataChangeObserver();
    }

    // triggered when the data-source has been updated (fetched from the API)
    @action dataChangeObserver() {
        const { stores } = this.props;

        // pre-select the first element
        if ((stores.tripStore.tripLogs || []).length > 0) {
            const trip = stores.tripStore.tripLogs[0];
            this.setCurrentLog(trip);
        }
    }

    // set/unset the selected log item
    @action setCurrentLog(log: Undef<LogItem<TripModel>>) {
        this.currentLog = this.currentLog === log ? undefined : log;

        //this.props.stores.mapStore.setRoute((this.currentLog || {}).data);
    }

    @action setCurrentTab(id: string) {
        this.currentTab = id;
    }

    render() {
        const { t, stores } = this.props;

        const tripLogs = stores.tripStore.tripLogs;
        return (
            <>
                {tripLogs && tripLogs.length > 0 && (
                    <SidePanel>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={this.currentTab}
                            onSelect={this.setCurrentTab.bind(this)}
                        >
                            <Tab
                                className={'tabs-title'}
                                eventKey="title"
                                title={<h2>{t('title.trip', { id: tripLogs[0].data.id })}</h2>}
                                disabled
                            />

                            <Tab eventKey="logs" title={t(`label.logs`)}>
                                <EventSelect
                                    id="trip-panel-event-select"
                                    onChange={(selectedEvents) =>
                                        (this.selectedEvents = selectedEvents)
                                    }
                                    logs={tripLogs}
                                />
                                <Accordion activeKey={`${(this.currentLog || {}).id}`}>
                                    {tripLogs
                                        .filter((l) => filterByEvents(l, this.selectedEvents))
                                        .map((i) => (
                                            <Card text="white" border="light" key={i.id}>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey={`${i.id}`}
                                                    onClick={this.setCurrentLog.bind(this, i)}
                                                >
                                                    <b className="float-right" title={i.event}>
                                                        <EventTranslator logItem={i} />
                                                    </b>
                                                    {moment(i.timestamp).format('LT L')}
                                                    {' - '}
                                                    {t(`log.reason.${i.reason}`)}
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={`${i.id}`}>
                                                    <Card.Body>
                                                        <TripInner
                                                            item={i.data}
                                                            log={i}
                                                            showVehicleLink={true}
                                                        />
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                </Accordion>
                            </Tab>
                        </Tabs>
                    </SidePanel>
                )}
            </>
        );
    }
}

export default withTranslation()(TripPanel);
