import React from 'react';
import { observer } from 'mobx-react';

type ThisComponentProps = {
    value: number;
    total: number;
};

const Ratio = ({ value, total }: ThisComponentProps) => {
    return <span>{Math.round((100 * value) / (total || 1))}%</span>;
};

export default observer(Ratio);
