import MapStore from './mapStore';
import VehicleStore from './vehicleStore';
import UiStore from './uiStore';
import RouteStore from './routeStore';
import ReportStore from './reportStore';
import TripStore from './tripStore';
import ConfigStore from './configStore';

export default class MainStore {
    mapStore: MapStore;
    vehicleStore: VehicleStore;
    routeStore: RouteStore;
    tripStore: TripStore;
    reportStore: ReportStore;
    configStore: ConfigStore;
    uiStore: UiStore;

    constructor() {
        this.mapStore = new MapStore();
        this.vehicleStore = new VehicleStore();
        this.routeStore = new RouteStore();
        this.tripStore = new TripStore();
        this.reportStore = new ReportStore();
        this.configStore = new ConfigStore();
        this.uiStore = new UiStore();
    }
}
