import React from 'react';

const LoadSpinner = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{ margin: 'auto', background: 'transparent', display: 'block' }}
            width="50px"
            height="50px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <g transform="translate(50 50)">
                <g transform="scale(1)">
                    <g transform="translate(-50 -50)">
                        <g transform="rotate(152.1 50 50)">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                repeatCount="indefinite"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"
                                dur="0.49019607843137253s"
                            />
                            <path
                                fillOpacity="1"
                                fill="#f3f3f3"
                                d="M50 50L50 0A50 50 0 0 1 100 50Z"
                            />
                        </g>
                        <g transform="rotate(24.103 50 50)">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                repeatCount="indefinite"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"
                                dur="0.6535947712418301s"
                            />
                            <path
                                fillOpacity="1"
                                fill="#d8dddf"
                                d="M50 50L50 0A50 50 0 0 1 100 50Z"
                                transform="rotate(90 50 50)"
                            />
                        </g>
                        <g transform="rotate(256.05 50 50)">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                repeatCount="indefinite"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"
                                dur="0.9803921568627451s"
                            />
                            <path
                                fillOpacity="1"
                                fill="#b8babd"
                                d="M50 50L50 0A50 50 0 0 1 100 50Z"
                                transform="rotate(180 50 50)"
                            />
                        </g>
                        <g transform="rotate(128.025 50 50)">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                repeatCount="indefinite"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"
                                dur="1.9607843137254901s"
                            />
                            <path
                                fillOpacity="1"
                                fill="#a3a3ac"
                                d="M50 50L50 0A50 50 0 0 1 100 50Z"
                                transform="rotate(270 50 50)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default LoadSpinner;
