import 'rc-slider/assets/index.css';

import React from 'react';
import Slider from 'rc-slider';
import { observer } from 'mobx-react';

type ThisComponentProps = {
    value: number;
    min: number;
    max: number;
    step: number;
    onChange: Function;
};

type SliderMarks = { [key: number]: number };

const genMarks = (from: number, to: number, step: number): SliderMarks => {
    const marks: SliderMarks = {};

    for (let i = from; i <= to; i += step) {
        marks[i] = i;
    }

    return marks;
};

const SliderSelect = ({ value, min, max, step, onChange }: ThisComponentProps) => {
    return (
        <Slider
            defaultValue={value}
            min={min}
            max={max}
            step={step}
            marks={genMarks(min, max, step)}
            onChange={onChange}
        />
    );
};

export default observer(SliderSelect);
